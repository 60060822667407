import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal, Input } from "antd";
import { ItemForm } from "../../components/Modal/ItemForm";
import { itemTable } from "../../components/tables/ItemTable";
import { getMethod } from "../../api/functions";

function Item(params) {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(null);
  const [q, setQ] = useState("");
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;
      if (key == "ArrowRight") {
        const p = currentPage;
        getData({ page: total / 10 > p ? p + 1 : p });
      }
      if (key == "ArrowLeft") {
        const p = currentPage;
        getData({ page: p > 1 ? p - 1 : p });
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [total, currentPage]);
  useEffect(() => {
    getData({ q });
  }, [q]);
  const getData = async (params) => {
    setLoader(true);
    var res = await getMethod("items/detail", params);
    if (res?.success) {
      const { currentPage, total, data } = res;
      setCurrentPage(currentPage);
      setTotal(total);
      setData(data);
    }
    setLoader(false);
  };
  const onUpdate = (item) => {
    setItem(item);
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Input
        placeholder="Search"
        value={q}
        onChange={(e) => setQ(e.target.value)}
      />
      <Divider />
      <Table
        loading={loader}
        pagination={{
          position: ["bottomLeft"],
          total: total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (page) => getData({ page }),
        }}
        columns={itemTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <ItemForm
          visible={show}
          item={item}
          edit={item ? true : false}
          onClose={() => {
            setShow(false);
            getData({ page: 1 });
            setItem(null);
          }}
        />
      )}
    </div>
  );
}

export default Item;
