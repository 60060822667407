import React, { useState } from 'react'
import { Modal, Form, Input, Button } from "antd";
import { postMethod } from '../../api/functions';
import { ShowMessage } from '../../components/toast';


const Adjesment = ({ onClose, visible, data = {} }) => {
  let [newValue, setNewValue] = useState(String(data.totalQuantity))
  let [loading, setLoading] = useState(false)
  const onsubmit = async () => {
    setLoading(true)
    let res = await postMethod("items/adjustment", { ...data, newValue })
    if (res?.success) {
      ShowMessage("success", "success");
      onClose()
    }
    setLoading(false)
  }
  return (
    <div>
        <Modal
        title={"Adjesment"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
     <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 15 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
         
          <Form.Item
            label="New Stock"
            name="newValue"
            initialValue={data.totalQuantity}
            rules={[{ required: true, message: "Please input your New Stock!" }]}
          >
            <Input value={newValue} onChange={evt => setNewValue(evt.target.value)} />
          </Form.Item>
          <Form.Item
            label="Current Stock"
            name="totalQuantity"
            initialValue={data.totalQuantity}
          >
            <Input disabled/>
          </Form.Item>
          <label>{`Difference is ${newValue - data.totalQuantity}`}</label>
          <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
            <Button disabled={newValue - data.totalQuantity === 0 || loading} loading={loading} onClick={onsubmit} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

      </Modal>

    </div>
  )
}

export default Adjesment