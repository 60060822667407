import React, { useState } from "react";
import { Modal, Form, Input, InputNumber, Button, TreeSelect } from "antd";
import { postMethod, putMethod } from "../../api/functions";
import { ShowMessage } from "../toast";
import { useSelector } from "react-redux";

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const SupplierForm = ({ visible, onClose, data }) => {
  const [loader, setLoader] = useState(false);
  const menufecturers = useSelector((state) => state.meta.menufecturers);
  const onFinish = async (values) => {
    setLoader(true);
    let res;
    if (!data?._id) res = await postMethod("supplier/detail", values);
    else res = await putMethod("supplier/detail", { ...values, _id: data._id });
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };
  return (
    <>
      <Modal
        title="Add Supplier"
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          {!data ? (
            <>
              <Form.Item
                name={"name"}
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ type: "email" }]}
                name={"email"}
                label="Email"
              >
                <Input />
              </Form.Item>
              <Form.Item name={"address"} label="Address">
                <Input />
              </Form.Item>
              <Form.Item name={"contact"} label="Contact">
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    type: "array",
                  },
                ]}
                initialValue={data?.menufecturers || []}
                name={"menufecturers"}
                label="Manufecturers"
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  filterTreeNode={(value, node) =>
                    node.title.toLowerCase().match(value)
                  }
                >
                  {menufecturers.map((i) => (
                    <TreeSelect.TreeNode value={i._id} title={i.name} />
                  ))}
                </TreeSelect>
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button loading={loader} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
