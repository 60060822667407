import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal, Input } from "antd";
import { PurchaseForm } from "../../components/Modal/PurchaseForm";
import { purchaseTable } from "../../components/tables/PurchaseTable";
import { getMethod } from "../../api/functions";
import { PurchaseSummary } from "../../components/Modal/PurchaseSummary";
import { journalTable } from "../../components/tables/account";
import CreateVouchersModal from "../../components/Modal/CreateVoucher";

function Journal(params) {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [q, setQ] = useState("");
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;
      if (key == "ArrowRight") {
        const p = currentPage;
        getData({ page: total / 10 > p ? p + 1 : p });
      }
      if (key == "ArrowLeft") {
        const p = currentPage;
        getData({ page: p > 1 ? p - 1 : p });
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [total, currentPage]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async (params) => {
    setLoader(true);
    var res = await getMethod("voucher/detail", params);
    if (res?.success) {
      const { currentPage, total, data } = res;
      setCurrentPage(currentPage);
      setTotal(total);
      setData(data);
    }
    setLoader(false);
  };
  const setSearch = (e) => {
    var { value } = e.target
    setQ(value)
    getData({ q: value })
  }
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">Add Voucher</Button>
      <Divider />
      <Input placeholder="Search" value={q} onChange={setSearch} />
      <Divider />
      <Table
        loading={loader}
        pagination={{
          position: ["bottomLeft"],
          total: total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (p) => {
            getData({ page: p });
            setCurrentPage(p);
          },
        }}
        columns={journalTable()}
        dataSource={data}
      />
      {show && (
        <CreateVouchersModal
          visible={show}
          onClose={() => {
            setShow(false);
            getData();
          }}
        />
      )}
    </div>
  );
}

export default Journal;
