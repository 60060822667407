import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal } from "antd";
import { getMethod } from "../../api/functions";
import { useDispatch, useSelector } from "react-redux";
import { setDataAction } from "../../store/actions";
import { accountCategoriesTable } from "../../components/tables/account";
import { AccountCategoryModel } from "../../components/Modal/AccountCategory";

function AccountCategory(params) {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const data = useSelector((state) => state.meta.accCategories);
  const dispatch = useDispatch();
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("users/account-categories");
    if (res?.success) dispatch(setDataAction({ accCategories: res?.data }));
    setLoader(false);
  };
  const onUpdate = (item) => {
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        tableLayout="fixed"
        loading={loader}
        pagination={false}
        columns={accountCategoriesTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <AccountCategoryModel
          title="Add Account Category"
          visible={show}
          customers={true}
          onClose={() => {
            setShow(false);
            getData();
          }}
        />
      )}
    </div>
  );
}

export default AccountCategory;
