import React, { useState } from "react";
import { Modal, Form, Input, InputNumber, Button } from "antd";
import { postMethod } from "../../api/functions";
import { ShowMessage } from "../toast";

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const ManufacturerForm = ({ visible, onClose }) => {
  const [loader, setLoader] = useState(false);
  const onFinish = async (values) => {
    setLoader(true);
    const res = await postMethod("menufecturer/detail", values);
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };

  return (
    <>
      <Modal
        title="Add Manufacturer"
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"name"}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item rules={[{ type: "email" }]} name={"email"} label="Email">
            <Input />
          </Form.Item>
          <Form.Item name={"address"} label="Address">
            <Input />
          </Form.Item>
          <Form.Item name={"contact"} label="Contact">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button loading={loader} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
