import { Col, Modal, Row, Table } from "antd";
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { purchaseSummaryTable } from "../tables/PurchaseTable";
import { useReactToPrint } from "react-to-print";
/* eslint-disable no-template-curly-in-string */
import SalePrint from "../Form/SalePrint";
export const PurchaseSummary = ({
  visible,
  onClose,
  items,
  data,
  supplier: supplierProp,
  title,
  tableData,
  print,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;
      if (key == "Enter" && print) {
        handlePrint();
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [print]);
  const suppliers = useSelector((state) => state.meta.suppliers);
  var supplier = {};
  if (supplierProp) supplier = supplierProp;
  else if (data.supplier)
    supplier = suppliers.find((i) => i._id == data.supplier);

  return (
    <>
      <Modal
        title={title ?? "Purchase Summary"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
        width={"70vw"}
        cancelText="Close"
      >
        {supplierProp && (
          <Row gutter={24}>
            <Col span={12}>
              <b>Invoice#: {data.invoiceNo}</b>
            </Col>
            <Col span={6} offset={6}>
              <b>Supplier: {supplier?.name || ""}</b>
            </Col>
          </Row>
        )}
        <Table
          pagination={false}
          rowKey={(i) => i._id}
          columns={tableData ?? purchaseSummaryTable()}
          dataSource={items}
        />
        <Row gutter={24}>
          {print && (
            <>
              <div style={{ display: "none" }}>
                <SalePrint data={print} ref={componentRef} />
              </div>
            </>
          )}
          <Col span={2} offset={22}>
            <b>Total: {data?.grandTotal ?? ""}</b>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
