import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Card,
  Space,
} from "antd";
import { postMethod } from "../../api/functions";
import { ShowMessage } from "../toast";

const { Option } = Select;
/* eslint-disable no-template-curly-in-string */
const MAIN = ["assets", "liability", "equity", "cogs", "expenses", "sales"];
const SUB = {
  assets: ["current_assets", "fixed_assets"],
  liability: ["current_liability", "fixed_liability"],
  equity: ["capital"],
  cogs: ["cogs"],
  expenses: ["expenses"],
  sales: ["sales"],
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const AccountCategoryModel = ({ visible, onClose, customers, title }) => {
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [subTypes, setSubType] = useState("");
  const onFinish = async (values) => {
    if (!type) return ShowMessage("Please Select Type");
    if (!subTypes) return ShowMessage("Please Select Sub Type");
    setLoader(true);
    const res = await postMethod("users/account-categories", {
      ...values,
      type,
      subTypes,
    });
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };
  useEffect(() => {
    if (type) setSubType(SUB[type][0]);
  }, [type]);
  return (
    <>
      <Modal
        title={title ?? "Add Manufacturer"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"title"}
            label="Title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Space direction="vertical" style={{ width: "100%" }}>
            <label>Type</label>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={type}
              onChange={setType}
              placeholder="Please select"
              allowClear
            >
              {MAIN.map((i) => (
                <Option value={i}>{i}</Option>
              ))}
            </Select>
            {type && (
              <>
                <label>Sub Type</label>
                <Select
                  showSearch
                  value={subTypes}
                  onChange={setSubType}
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  allowClear
                >
                  {SUB[type].map((i) => (
                    <Option value={i}>{i}</Option>
                  ))}
                </Select>
              </>
            )}
          </Space>

          <Form.Item>
            <Button
              style={{ marginTop: 20 }}
              loading={loader}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
