import { Col, DatePicker, Divider, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";
import { itemStockTable } from "../../components/tables/ItemTable";
import { saleStatTable, saleTable } from "../../components/tables/SaleTable";

const { RangePicker } = DatePicker;
const { Option } = Select;
function StockReport({}) {
  const [item, setItem] = useState("");
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    q: "",
  });
  const [data, setData] = useState([]);
  const [lastBalance, setLastBalance] = useState("");
  const [firstItem, setFirstItem] = useState(null);
  const [items, setItems] = useState([]);
  useEffect(() => {
    getData({ q: state.q });
  }, [state.q]);
  const getData = async (params) => {
    var res = await getMethod("items/detail", params);
    if (res?.success) {
      const { data } = res;
      setItems(data);
    }
  };
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };
  useEffect(() => {
    if (state.endDate && state.startDate && item) {
      getItemHistory();
    }
  }, [state.endDate, state.startDate, item]);
  const getItemHistory = async () => {
    setLoader(true);
    console.log({ item })
    var res = await getMethod("items/stock-history", {
      endDate: state.endDate,
      startDate: state.startDate,
      item,
    }, ["allWithSaleDate", "all"].includes(item) ? "blob" : null);
    if (res?.success) {
      const { data } = res;
      var firstItem = data[0];
      if (firstItem) {
        setFirstItem({ ...firstItem });
        var openingBalance = firstItem.openingBalance;
        var items = data.map((item) => {
          var balance;
          if (item.type == "in-flow") balance = item.quantity + openingBalance;
          else balance = openingBalance - item.quantity;
          item.openingBalance = balance;
          openingBalance = balance;
          return { ...item };
        });
        setLastBalance(openingBalance);
        setData(items);
      }
    }
    setLoader(false);
  };
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Select Item</label>
          <Select
            showSearch
            searchValue={state.q}
            onSearch={(q) => setState((s) => ({ ...s, q }))}
            value={item}
            onChange={setItem}
            filterOption={false}
            style={{ width: "100%" }}
            placeholder="Please select"
            allowClear
          >
            <Option value={""}>Select</Option>
            <Option value={"all"}>All</Option>
            <Option value={"allWithSaleDate"}>All With Last Sale Date</Option>
            {items.map((i) => (
              <Option value={i._id}>{i.name}</Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <label>Select Range</label>
          <br />
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={setDate}
            style={{ width: "100%" }}
            picker={"daily"}
          />
        </Col>
      </Row>
      <Divider />
      {firstItem && <h1>{"Opening Balance: " + firstItem.openingBalance}</h1>}
      {firstItem && <h1>{"Closing Balance: " + lastBalance}</h1>}
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={itemStockTable({})}
        dataSource={data}
      />
    </div>
  );
}

export default StockReport;
