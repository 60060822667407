import React, { useState } from "react";
import { Modal, Form, Input, InputNumber, Button, Switch } from "antd";
import { postMethod, putMethod } from "../../api/functions";
import { ShowMessage } from "../toast";

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const ItemCategoryForm = ({ visible, onClose, customers, update, title }) => {
  const [loader, setLoader] = useState(false);
  const onFinish = async (values) => {
    setLoader(true);
    var res;
    if (update) {
      res = await putMethod(
        customers ? "customers/detail" : "item-category/detail",
        { ...values, _id: update._id }
      );
    } else
      res = await postMethod(
        customers ? "customers/detail" : "item-category/detail",
        values
      );
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };

  return (
    <>
      <Modal
        title={title ? title : customers ? "Add Customer" : "Add Manufacturer"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            initialValue={update?.name}
            name={"name"}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!customers && (
            <Form.Item
              initialValue={update?.discount}
              name={"discount"}
              label="Discount"
              rules={[
                {
                  required: true,
                  type: "boolean",
                },
              ]}
            >
              <Switch
                defaultChecked={
                  typeof update?.discount == "undefined"
                    ? true
                    : update?.discount
                }
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button loading={loader} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
