export const customersTable = ({ onUpdate }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  // {
  //   title: "Action",
  //   key: "action",
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <a onClick={() => onUpdate(record)}>Update</a>
  //     </Space>
  //   ),
  // },
];
