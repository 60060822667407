import React, { useState } from "react";
import { Form, Input, InputNumber, Button } from "antd";
import Password from "antd/lib/input/Password";
import { postMethod } from "../../api/functions";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export default function Login() {
  const [loader, setLoader] = useState(false);
  const handleLogin = async (values) => {
    setLoader(true);
    var res = await postMethod("users/login", values);
    if (res?.success) {
      localStorage.setItem("user", JSON.stringify(res?.data));
      localStorage.setItem("admin", JSON.stringify(res?.admin));
      window.location.href = "/main/";
    }
    setLoader(false);
  };
  return (
    <div style={{ marginTop: 100 }} className="card">
      <Form
        name="nest-messages"
        onFinish={handleLogin}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={"username"}
          label="Username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"password"}
          label="Password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Password />
        </Form.Item>

        <Form.Item>
          <Button loading={loader} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
