import React, { useState } from 'react'
import { Modal, Input, Button } from "antd";
import Password from "antd/lib/input/Password";
import { ShowMessage } from '../../components/toast';


const SecurityModal = ({ onClose, visible, onSuccess, adjustment }) => {
    let [code, setCode] = useState("")
    const onsubmit = async () => {
        let valid = false
        if (adjustment && code === process.env.REACT_APP_ADJUSTMENT_CODE) valid = true
        if (valid) onSuccess()
        else ShowMessage("Wrong Code Entered!")
    }
    return (
        <div>
            <Modal
                title={"Security Code"}
                visible={visible}
                okButtonProps={{ hidden: true }}
                onCancel={onClose}
            >
                <Password value={code} onChange={evt => setCode(evt.target.value)} />
                <Button onClick={onsubmit} type="primary" htmlType="submit">
                    Submit
                </Button>
            </Modal>

        </div>
    )
}

export default SecurityModal