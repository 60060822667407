import { Button, Col, Divider, Row, Select, Table, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod } from "../../api/functions";
import CreateVouchersModal from "../../components/Modal/CreateVoucher";
import { ledgerTable } from "../../components/tables/account";
const { Option } = Select;
const { RangePicker } = DatePicker;
function Ledger(params) {
  const [show, setShow] = useState(false);
  const [account, setAccount] = useState("");
  const [loader, setLoader] = useState(true);
  const accounts = useSelector((state) => state.meta.accounts);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date()
  })
  const [data, setData] = useState({
    previousBalance: 0,
    data: [],
    closingBalance: 0,
    totalCr: 0,
    totalDr: 0,
  });
  useEffect(() => {
    getData();
  }, [account, date]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("voucher/ledger", { ...params, account, ...date });
    if (res?.success) {

      setData(res);
    }
    setLoader(false);
  };
  const onDateChange = (e) => {
    const [startDate, endDate] = e;
    setDate({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">Add Voucher</Button>
      <Divider />
      <label>Select Account</label>
      <Row gutter={24}>
        <Col span={12}>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Please select..."
            allowClear
            onChange={setAccount}
            value={account}
            filterOption={(value, node) => node.children.toLowerCase().match(value)}
          >
            {accounts.map((i) => (
              <Option value={i._id}>
                {i.title +
                  " - " +
                  i.category?.type +
                  " - " +
                  Math.abs(i.balance) +
                  (i.balance >= 0 ? "CR" : "DR")}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={onDateChange}
            style={{ width: "100%" }}
            picker={"daily"}
          />
        </Col>
      </Row>

      {
        account && (<>
          <Divider />
          <Row gutter={24}>
            <Col offset={20}>{`Previous Balance: ${data.previousBalance}`}</Col>
          </Row>
          <Divider />
          <Table
            loading={loader}
            pagination={false}
            columns={ledgerTable()}
            dataSource={[...data.data, {
              crAmount: data.totalCr,
              drAmount: data.totalDr,
              balance: data.closingBalance,
              accountTo: {
                title: "Total:"
              }
            }]}
          />
        </>
        )
      }
      {
        show && (
          <CreateVouchersModal
            visible={show}
            onClose={() => {
              setShow(false);
              getData();
            }}
          />
        )
      }
    </div >
  );
}

export default Ledger;
