import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Card,
  Space,
} from "antd";
import { postMethod } from "../../api/functions";
import { ShowMessage } from "../toast";
import { useSelector } from "react-redux";

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const AccountsEditModal = ({ visible, onClose, customers, title }) => {
  const [loader, setLoader] = useState(false);
  const accCategories = useSelector((state) => state.meta.accCategories);
  const suppliers = useSelector((state) => state.meta.suppliers);
  const _customers = useSelector((state) => state.meta.customers);
  const onFinish = async (values) => {
    setLoader(true);
    const res = await postMethod("users/accounts", {
      ...values,
    });
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };
  return (
    <>
      <Modal
        title={title ?? "Add Manufacturer"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"title"}
            label="Title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"category"}
            label="Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              allowClear
            >
              {accCategories.map((i) => (
                <Option value={i._id}>{i.title}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"supplier"} label="Supplier">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              allowClear
            >
              {suppliers.map((i) => (
                <Option value={i._id}>{i.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"customer"} label="Customer">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select"
              allowClear
            >
              {_customers.map((i) => (
                <Option value={i._id}>{i.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ marginTop: 20 }}
              loading={loader}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
