import { Space } from "antd";
import moment from "moment";

export const purchaseReturnTable = ({ onUpdate, onView, onAccountPress }) => [
  {
    title: "Invoice #",
    dataIndex: "invoice",
    key: "invoice",
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t) => (
      <p>{moment(t).isValid() ? moment(t).format("YYYY-MM-DD") : "-"}</p>
    ),
  },
  {
    title: "Total Amount",
    dataIndex: "grandTotal",
    key: "grandTotal",
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    render: (cat) => cat?.name || "-",
  },

  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onView(record)}>View</a>
        {/* <a
          style={record.accountAdded ? { textDecoration: "line-through" } : {}}
          onClick={() => !record.accountAdded && onUpdate(record)}
        >
          Update
        </a> */}
        {/* <a onClick={() => onAccountPress(record)}>Add to account</a> */}
      </Space>
    ),
  },
];
export const purchaseTable = ({ onUpdate, onView, onAccountPress }, draft) => [
  {
    title: "Invoice #",
    dataIndex: "invoice",
    key: "invoice",
  },
  {
    title: "Supplier Invoice #",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t) => (
      <p>{moment(t).isValid() ? moment(t).format("YYYY-MM-DD") : "-"}</p>
    ),
  },
  {
    title: "Total Amount",
    dataIndex: "grandTotal",
    key: "grandTotal",
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    render: (cat) => cat?.name || "-",
  },

  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onView(record)}>View</a>
        {draft && <a
          onClick={() => onUpdate(record)}
        >
          Update
        </a>}
        {/* <a onClick={() => onAccountPress(record)}>Add to account</a> */}
      </Space>
    ),
  },
];
export const purchaseSummaryTable = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (cat, record) => record?.item?.name || cat || "-",
  },
  {
    title: "Batch#",
    dataIndex: "batch",
    key: "batch",
    render: (cat) => cat || "-",
  },
  {
    title: "Expiry",
    dataIndex: "expiry",
    key: "expiry",
    render: (t) => (
      <p>{moment(t).isValid() ? moment(t).format("YYYY-MM-DD") : "-"}</p>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (cat) => cat || "-",
  },
  {
    title: "Purchase Price",
    dataIndex: "purchasePrice",
    key: "purchasePrice",
    render: (cat) => cat || "-",
  },
  {
    title: "Sale Price",
    dataIndex: "totalSalePrice",
    key: "totalSalePrice",
    render: (cat) => cat || "-",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (cat, record) =>
      record?.discount ?? record?.discountInPercent ?? "-",
  },
  {
    title: "Ex Quantity",
    dataIndex: "extrawQuantity",
    key: "extrawQuantity",
    render: (cat) => cat || "-",
  },
  {
    title: "Total",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (cat) => cat || "-",
  },
];
