import { Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";

export const itemTable = ({ onUpdate }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Selling Price",
    dataIndex: "salePrice",
    key: "salePrice",
  },
  {
    title: "Purchase Price",
    dataIndex: "purchasePrice",
    key: "purchasePrice",
  },
  {
    title: "Menufecturers",
    dataIndex: "menufecturers",
    key: "menufecturers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
  {
    title: "Suppliers",
    dataIndex: "suppliers",
    key: "suppliers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onUpdate(record)}>Update</a>
      </Space>
    ),
  },
];
export const tableCheckBoxItems = ({ addItem, selectedItems }) => [
  {
    title: "Check",
    dataIndex: "_id",
    key: "_id",
    render: (i, r) => {
      return (
        <Checkbox
          checked={selectedItems.some((it) => it._id == i)}
          onChange={(e) => addItem(e.target.checked, r)}
        />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Selling Price",
    dataIndex: "salePrice",
    key: "salePrice",
  },
  {
    title: "Purchase Price",
    dataIndex: "purchasePrice",
    key: "purchasePrice",
  },
  {
    title: "Menufecturers",
    dataIndex: "menufecturers",
    key: "menufecturers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
  {
    title: "Suppliers",
    dataIndex: "suppliers",
    key: "suppliers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
];
export const tableCheckBoxItemsSaleReturn = ({ addItem, selectedItems }) => [
  {
    title: "Check",
    dataIndex: "_id",
    key: "_id",
    render: (i, r) => {
      return (
        <Checkbox
          checked={selectedItems.some((it) => it._id == i)}
          onChange={(e) => addItem(e.target.checked, r)}
        />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "item",
    key: "item",
    render: (i) => <p>{i.name}</p>,
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },

  {
    title: "Selling Price",
    dataIndex: "salePrice",
    key: "salePrice",
  },
];
export const tableCheckBoxItemsSale = ({ addItem, selectedItems }) => [
  {
    title: "Check",
    dataIndex: "_id",
    key: "_id",
    render: (i, r) => {
      return (
        <Checkbox
          checked={selectedItems.some((it) => it._id == i)}
          onChange={(e) => addItem(e.target.checked, r)}
        />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Selling Price",
    dataIndex: "salePrice",
    key: "salePrice",
  },

  {
    title: "Menufecturers",
    dataIndex: "menufecturers",
    key: "menufecturers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
  {
    title: "Suppliers",
    dataIndex: "suppliers",
    key: "suppliers",
    render: (prop) => prop?.map((i) => i.name).join(", "),
  },
];
export const itemHistoryTable = () => [
  {
    title: "Invoice #",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "_id",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Purchase Price",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.purchasePrice || "-",
  },
  {
    title: "Selling Price",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.salePrice || "-",
  },
  {
    title: "Batch #",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.batch || "-",
  },
  {
    title: "Expiry Date",
    dataIndex: "items",
    key: "_id",
    render: (cat) => (cat ? moment(cat.expiry).format("DD/MM/YYYY") : "-"),
  },
  {
    title: "Extraw Quantity",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.extrawQuantity || "-",
  },
  {
    title: "Total Price",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.totalPrice || "-",
  },
  {
    title: "Quantity",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.quantity || "-",
  },
  {
    title: "Discount",
    dataIndex: "items",
    key: "_id",
    render: (cat) => cat?.discountInPercent || "-",
  },
];
export const itemStockTable = () => [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Invoice No",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
    render: (cat) => cat || "-",
  },
  {
    title: "Batch",
    dataIndex: "batch",
    key: "_id",
    render: (cat) => cat?.batch || "-",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "_id",
  },
  {
    title: "Sale Type",
    dataIndex: "saleType",
    key: "_id",
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "_id",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "_id",
    render: (cat) => cat?.name || "-",
  },
  {
    title: "Total Price",
    dataIndex: "price",
    key: "_id",
  },
  {
    title: "Per Unit Price",
    dataIndex: "item",
    key: "_id",
    render: (item, record) => record?.category == "purchase" || record?.category == "purchaseReturn" ? item.purchasePrice : item.salePrice
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "_id",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "_id",
  },
  {
    title: "Net Price",
    dataIndex: "totalPrice",
    key: "_id",
  },

  {
    title: "Remaining Stock",
    dataIndex: "openingBalance",
    key: "_id",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "_id",
  },
];

export const itemStockActivityReport = ({onUpdate}) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },
  {
    title: "Stock",
    dataIndex: "totalQuantity",
    key: "totalQuantity",
  },
  {
    title: "Unit Sale Price",
    dataIndex: "salePrice",
    key: "salePrice",
  },
  {
    title: "Net Price",
    dataIndex: "totalPrice",
    key: "totalPrice",
    
  },
  {
    title: "Adjustment",
    key: "action",
    render: (text, record) => !record.noUpdate ? (
      <Space size="middle">
        <a onClick={() => onUpdate(record)}>Update</a>
      </Space>
    ) : null,
  },
];

export const purchaseOrderTable = () => [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Sale QTY",
    dataIndex: "saleQty",
    key: "packing",
  },
  {
    title: "Sale Return QTY",
    dataIndex: "returnQty",
    key: "totalQuantity",
  },
  {
    title: "Net Sale QTY",
    dataIndex: "netSaleQty",
    key: "salePrice",
  },
  {
    title: "Average Sale",
    dataIndex: "averageSale",
    key: "totalPrice",
  },
  {
    title: "Stock In Hand",
    dataIndex: "stockInHand",
    key: "totalPrice",
  },
  {
    title: "Required Stock",
    dataIndex: "requiredStock",
    key: "totalPrice",
  },
  {
    title: "Packing",
    dataIndex: "packing",
    key: "packing",
  },
  {
    title: "Required Packs",
    dataIndex: "requiredPacks",
    key: "requiredPacks",
  },
];