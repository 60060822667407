import { Button, Divider, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMethod } from "../../api/functions";
import { ItemCategoryForm } from "../../components/Modal/itemCategoryForm";
import { customersTable } from "../../components/tables/customers";
import { setDataAction } from "../../store/actions";

function Customers(params) {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const data = useSelector((state) => state.meta.customers);
  const dispatch = useDispatch();
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("customers/detail");
    if (res?.success) dispatch(setDataAction({ customers: res?.data }));
    setLoader(false);
    var _res = await getMethod("users/accounts");
    if (_res?.success) dispatch(setDataAction({ accounts: _res?.data }));
  };
  const onUpdate = (item) => {
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        tableLayout="fixed"
        loading={loader}
        pagination={false}
        columns={customersTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <ItemCategoryForm
          visible={show}
          customers={true}
          onClose={() => {
            setShow(false);
            getData();
          }}
        />
      )}
    </div>
  );
}

export default Customers;
