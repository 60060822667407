import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal, Input, Row, Col, Select } from "antd";
import { PurchaseForm } from "../../components/Modal/PurchaseForm";
import { purchaseTable } from "../../components/tables/PurchaseTable";
import { getMethod } from "../../api/functions";
import { PurchaseSummary } from "../../components/Modal/PurchaseSummary";

function Purchase(params) {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [selected, setSelected] = useState("purchase")
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(null);
  const [item, setItem] = useState(null);
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;
      if (key == "ArrowRight") {
        const p = currentPage;
        getData({ page: total / 10 > p ? p + 1 : p });
      }
      if (key == "ArrowLeft") {
        const p = currentPage;
        getData({ page: p > 1 ? p - 1 : p });
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [total, currentPage]);
  useEffect(() => {
    getData();
  }, [q, selected]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod(selected == "purchase" ? "purchase/detail" : "purchase/draft", { ...params, q });
    if (res?.success) {
      const { currentPage, total, data } = res;
      setCurrentPage(currentPage);
      setTotal(total);
      setData(data);
    }
    setLoader(false);
  };
  const onView = (record) => {
    setItem(record);
  };
  const onUpdate = (record) => {
    setUpdate(record);
    setShow(true);
  };
  const onAccountPress = (record) => {};
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Row gutter={24}>
        <Col span={12}>
          <Input value={q} onChange={e => setQ(e.target.value)} placeholder="Search By Invoice #" />
        </Col>
        <Col span={6} offset={6} >
          <Select value={selected} onChange={setSelected} style={{ width: '100%' }}>
            <Select.Option value={"purchase"}>Purchase</Select.Option>
            <Select.Option value={"draft"}>Drafts</Select.Option>
          </Select>
        </Col>
      </Row>
      <Divider />
      <Table
        loading={loader}
        pagination={{
          position: ["bottomLeft"],
          total: total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          onChange: p => getData({ page: p }),
        }}
        columns={purchaseTable({ onView, onAccountPress, onUpdate }, selected == "draft")}
        dataSource={data}
      />
      {show && (
        <PurchaseForm
          visible={show}
          update={update}
          onClose={() => {
            setShow(false);
            getData({ page: 1 });
            setItem(null);
            setUpdate(null);
          }}
        />
      )}
      {item?.items?.length > 0 && (
        <PurchaseSummary
          visible={true}
          supplier={item.supplier}
          data={{ ...item, items: [] }}
          items={item.items}
          onClose={() => setItem(null)}
        />
      )}
    </div>
  );
}

export default Purchase;
