import moment from "moment";
import React from "react";
import image from "./Logo1.jpeg";
/* eslint-disable no-template-curly-in-string */
import "./salePrint.css";
const SalePrint = React.forwardRef(({ data }, ref) => {
    var {
        invoiceNo,
        customerId,
        items,
        priceWithDiscount,
        discountPrice,
        grandTotal,
    } = data;
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    var admin = localStorage.getItem("admin");
    admin = JSON.parse(admin);
    return (
        <div ref={ref}>
            <div class="ticket">
                <img src={image} alt="Logo" />
                <p class="centered">
                    {admin.name}
                    <br />
                    PH# {admin.contact}
                    <br />
                    Lic No. {admin.license}
                </p>
                <p>
                    Date - {moment().format("DD/MM/YYYY")}
                    <br />
                    Invoice# - {invoiceNo}
                    <br />
                    M/S - {customerId?.name ?? "CASH SALE CUSTOMER"}
                    <br />
                </p>
                <table >
                    <thead>
                        <tr>
                            <th class="description">Item Name</th>
                            <th class="quantity">Qty</th>
                            <th class="price">Price</th>
                        </tr>
                        <tr />
                    </thead>
                    <tbody>
                        {items?.map((item) => {
                            return (
                                <tr className="no-border-tr">
                                    <td class="description">{item.name}</td>
                                    <td class="quantity">{item.quantity}</td>
                                    <td class="price">{Number(item.priceWithDiscount).toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <hr style={{ marginTop: 5, marginBottom: -10 }} />
                <p >
                    <br />
                    Total Items: {items.length}
                    <br />
                    <table>
                        <tr className="no-border-tr">
                        <td class="description">Total:</td>
                        <td class="quantity"></td>
                            <td class="price bold-text">{Number(priceWithDiscount).toFixed(2)}</td>
                    </tr>
                    {discountPrice ? (
                            <tr className="no-border-tr">
                            <td class="description">Discount:</td>
                                <td class="quantity "></td>
                                <td class="price bold-text">{Number(discountPrice).toFixed(2)}</td>
                        </tr>
                    ) : null}
                        <tr className="no-border-tr">
                        <td class="description">Net Total:</td>
                        <td class="quantity"></td>
                            <td class="price bold-text">{Number(grandTotal).toFixed(2)}</td>
                    </tr>
                    </table>
                    <br />
                    <b>{user.name}</b>
                    <br />
                    {admin.discription}
                    (For Any Complaint Contact C.E.O (Nazir Hussain) 0322-4473103)
                </p>
                <p class="centered">
                    Thanks for your purchase! <br />
                    {admin.address}
                </p>
            </div>
        </div>
    );
});
export default SalePrint