import Companies from "./views/Companies";
import Manufacturer from "./views/Manufacturer";
import Item from "./views/Item";
import Supplier from "./views/Supplier";
import Purchase from "./views/Purchase";
import Users from "./views/Users";
import ItemCategories from "./views/itemCategories";
import Sale from "./views/Sale";
import Customers from "./views/customers";
import SaleHistory from "./views/SaleHistory";
import AccountCategory from "./views/AccountCategory";
import Accounts from "./views/Accounts";
import Vouchers from "./views/Vouchers";
import PurchaseReturn from "./views/PurchaseReturn";
import SaleReturn from "./views/SaleReturn";
import Journal from "./views/Journal";
import Ledger from "./views/Ledger";
import StockReport from "./views/StockReport";
import SaleReport from "./views/SaleReport";
import CashActivityReport from "./views/CashActivityReport";
import TrailBalance from "./views/TrailBalance";
import StockActivityReport from "./views/StockActivityReport";
import PurchaseOrder from "./views/PurchaseOrder";

const routes = [
  {
    layout: "/main",
    path: "/companies",
    name: "Companies",
    component: Companies,
    superAdmin: true,
    user: false,
  },
  {
    layout: "/main",
    path: "/users",
    name: "Users",
    component: Users,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/customers",
    name: "Customers",
    component: Customers,
    user: true,
    privileges: ["sale"],
  },
  {
    layout: "/main",
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/account-categories",
    name: "Account Categories",
    component: AccountCategory,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/vouchers",
    name: "Vouchers",
    component: Vouchers,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/journal",
    name: "Journal",
    component: Journal,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/ledger",
    name: "Ledger",
    component: Ledger,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/trail-balance",
    name: "Trail Balance",
    component: TrailBalance,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/stock-activity-report",
    name: "Stock Report",
    component: StockActivityReport,
    user: true,
    privileges: [],
  },

  {
    layout: "/main",
    path: "/Item",
    name: "Item",
    component: Item,
    user: true,
    privileges: ["item"],
  },
  {
    layout: "/main",
    path: "/item-category",
    name: "Item Category",
    component: ItemCategories,
    user: true,
    privileges: ["item"],
  },
  {
    layout: "/main",
    path: "/Manufacturer",
    name: "Manufacturer",
    component: Manufacturer,
    user: true,
    privileges: ["manufecturer"],
  },
  {
    layout: "/main",
    path: "/Supplier",
    name: "Suppliers",
    component: Supplier,
    user: true,
    privileges: ["supplier"],
  },
  {
    layout: "/main",
    path: "/Purchase",
    name: "Purchase",
    component: Purchase,
    user: true,
    privileges: ["purchase"],
  },
  {
    layout: "/main",
    path: "/Purchase-return",
    name: "Purchase Return",
    component: PurchaseReturn,
    user: true,
    privileges: ["purchase"],
  },
  {
    layout: "/main",
    path: "/sale",
    name: "Sale",
    component: Sale,
    user: true,
    privileges: ["sale"],
  },
  {
    layout: "/main",
    path: "/sale-return",
    name: "Sale Return",
    component: SaleReturn,
    user: true,
    privileges: ["sale"],
  },
  {
    layout: "/main",
    path: "/sale-history",
    name: "Sale History",
    component: SaleHistory,
    user: true,
    privileges: ["sale"],
  },
  {
    layout: "/main",
    path: "/sale-report",
    name: "Sale Report",
    component: SaleReport,
    user: true,
    privileges: ["sale"],
  },
  {
    layout: "/main",
    path: "/stock-history",
    name: "Item Stock Report",
    component: StockReport,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/cash-activity-report",
    name: "Cash Activity Report",
    component: CashActivityReport,
    user: true,
    privileges: [],
  },
  {
    layout: "/main",
    path: "/purchase-order",
    name: "Purchase Order",
    component: PurchaseOrder,
    user: true,
    privileges: [],
  },
];

export default routes;
