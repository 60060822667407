import { DatePicker, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";
import { ledgerTable, trailBalanceTable } from "../../components/tables/account";
import moment from "moment";

const { RangePicker } = DatePicker;
function TrailBalance(params) {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    data: [],
  });
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    getData();
  }, [state]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("voucher/trail-balance", { ...params, ...state });
    if (res?.success) {

      setData(res);
    }
    setLoader(false);
  };
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };
  return (
    <div>
      <RangePicker
        format={"DD/MM/YYYY"}
        onChange={setDate}
        style={{ width: "100%" }}
      />
      <Table
        loading={loader}
        pagination={false}
        columns={trailBalanceTable()}
        dataSource={[...data.data]}
      />
    </div >
  );
}

export default TrailBalance;
