import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Select,
  Table,
  TreeSelect,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod, postMethod, putMethod } from "../../api/functions";
import { tableCheckBoxItems } from "../tables/ItemTable";
import { ShowMessage } from "../toast";
import { ItemPurchaseHistory } from "./itemPurchaseHistory";
import { PurchaseSummary } from "./PurchaseSummary";
const { Option } = Select;
/* eslint-disable no-template-curly-in-string */
const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const ListRow = ({
  dispItem,
  feild,
  item,
  totalPrice,
  feilds,
  index,
  getHistory,
  setPrice,
}) => {
  const [stock, setStock] = useState([]);
  const [batch, setBatch] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getStock();
  }, []);
  useEffect(() => {
    if (item.batch) {
      setBatch(item.batch)
    }
  }, [item.batch])
  const getStock = async () => {
    var res = await getMethod("items/stock", { item: item?._id });
    if (res?.success) {
      setStock(res.data);
    }
    setLoader(false);
  };
  if (loader)
    return (
      <>
        <div className="loader1" />
        {index < feilds.length - 1 && <hr />}
      </>
    );
  else if (stock.length > 0) {
    const selectedBatch = stock.find((i) => i._id == batch);
    var quantityToReturn = 0;
    if (selectedBatch) {
      console.log(selectedBatch)
      quantityToReturn = ((selectedBatch.quantity * selectedBatch.packing) + selectedBatch.availablePacking) / selectedBatch.packing;
      quantityToReturn = Math.floor(quantityToReturn)
    }
    return (
      <>
        <Row gutter={24} justify="space-between">
          <Col span={4}>
            <h3>{dispItem.name + "-" + dispItem.packing + "(Packing)"}</h3>
            <Button
              onClick={() => getHistory(item._id)}
              type="primary"
              size="small"
              style={{ marginLeft: 10 }}
            >
              Check
            </Button>
          </Col>
          <Col span={4}>
            <Form.Item
              {...feild}
              name={[feild.name, "batch"]}
              label="Batch No:"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                value={batch}
                onChange={setBatch}
                placeholder="Please select"
                allowClear

                style={{ width: 170 }}
              >
                {stock.map((i) => (
                  <Option value={i._id}>{i.batch}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              {...feild}
              name={[feild.name, "quantity"]}
              label={`QTY (${quantityToReturn || ""})`}
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                  max: quantityToReturn,
                },
              ]}
            >
              <InputNumber defaultValue={item.quantity} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              {...feild}
              name={[feild.name, "purchasePrice"]}
              label="Purchase Price"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber value={item.purchasePrice} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              {...feild}
              name={[feild.name, "totalSalePrice"]}
              label="Sale Price"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber value={item.totalSalePrice} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              {...feild}
              name={[feild.name, "discount"]}
              label="Discount"
              initialValue={item?.discountInPercent || item.discount}
              rules={[
                {
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber
                defaultValue={item?.discountInPercent || item.discount || 0}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              {...feild}
              name={[feild.name, "totalPrice"]}
              shouldUpdate={(a, b, info) => setPrice(a, b, info, index)}
              label="Total:"
            >
              {console.log(totalPrice[index])}
              <InputNumber disabled={true} value={totalPrice[index]} />
            </Form.Item>
          </Col>
        </Row>
        {index < feilds.length - 1 && <Divider />}
      </>
    );
  } else
    return <h3>{dispItem.name + "-" + dispItem.packing + " Out Of Stock"}</h3>;
};
export const PurchaseReturnForm = ({ visible, onClose }) => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({});
  const [summary, setSummary] = useState([]);
  const [items, setItems] = useState([]);
  const [price, setOkPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [history, setHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    supplier: "",

    grandTotal: "",
  });
  const suppliers = useSelector((state) => state.meta.suppliers);
  const onFinish = async (values) => {
    setLoader(true);
    var items = values.items || [];
    items = items.map((i, ind) => ({
      ...i,
      totalPrice: totalPrice[ind],
      priceWithDiscount: price[ind],
      discount: i.discount ? i.discount : 0,
      extrawQuantity: i.extrawQuantity ? i.extrawQuantity : 0,
    }));
    let res;

    res = await postMethod("purchase/return", {
      items,
      ...state,
      totalPrice: price.reduce((sum, i) => i + sum, 0),
    });
    if (res?.success) onClose();
    setLoader(false);
    // console.log(values);
  };
  const onchange = (key, val) => {
    setState((s) => ({ ...s, [key]: val }));
  };
  const getItems = async (params = {}) => {
    try {
      if (!state.supplier) throw new Error("Please Enter supplier");
      setTableLoading(true);
      setStep(2);
      var res = await getMethod("items/detail", {
        // supplier: state.supplier,
        ...params,
      });
      if (res?.success) {
        setItems(res.data);
      }
      setTableLoading(false);
    } catch (error) {
      ShowMessage(error?.message);
    }
  };
  const addItem = (val, item) => {
    setSelectedItems((items) => {
      if (val) return [...items, item];
      else return items.filter((i) => i._id != item._id);
    });
  };
  const searching = (e) => {
    setSearch(e.target.value);
    getItems({ q: e.target.value });
  };
  const onStep3 = () => {
    var items
    if (form && form?.items) {
      setForm((fo) => {
        var temp = [...fo.items];
        items = [
          ...selectedItems.map((i) => {
            var item = temp.find((it) => it._id == i._id);
            if (item) return item;
            else return i;
          }),
        ];
        fo.items = [...items];
        return fo;
      });

    } else if (!form?.items) setForm((f) => ({ ...f, items: selectedItems }));
    setStep(3);
    if (items) {
      setTotalPrice(totalPrice.splice(0, items.length))
      setOkPrice(price.splice(0, items.length))
    }
  };
  const setPrice = (prevValues, currentValues, info, index) => {
    if (info.source == "internal") {
      var prevobj = prevValues.items[index];
      var currentObj = currentValues.items[index];
      var {} = prevobj;
      var {
        purchasePrice: cpurchasePrice,
        quantity: cquanity,
        discount: cdiscount,
      } = currentObj;
      if (cquanity && cpurchasePrice) {
        var price = cpurchasePrice * cquanity;
        var dis;
        if (!cdiscount) dis = 0;
        else dis = (cdiscount * price) / 100;
        var totalPrice = price - dis;
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = price;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = totalPrice;
          return [...temp];
        });
        return true;
      } else if (!cquanity || !cpurchasePrice) {
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (Array.isArray(totalPrice))
      onchange(
        "grandTotal",
        totalPrice.reduce((i, sum) => i + sum, 0)
      );
  }, [totalPrice]);
  // useEffect(() => {
  //   setSelectedItems([]);
  // }, [state.supplier]);
  const getHistory = async (itemId) => {
    var res = await getMethod("items/history", { item: itemId });
    if (res?.success) setHistory(res.data);
  };
  return (
    <>
      <Modal
        title="Purchase Return"
        visible={visible}
        centered
        maskClosable={false}
        width={"90vw"}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          {step == 1 ? (
            <Row gutter={24}>
              <Col span={24}>
                <label>Supplier</label>
                <TreeSelect
                  showSearch
                  value={state.supplier}
                  onChange={(e) => onchange("supplier", e)}
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  filterTreeNode={(value, node) => node.title.toLowerCase().match(value)}
                >
                  {suppliers.map((i) => (
                    <TreeNode value={i._id} title={i.name} />
                  ))}
                </TreeSelect>
              </Col>
              <Row className="top-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      getItems({});
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Row>
          ) : step == 2 ? (
            <>
              <Input placeholder="Search" value={search} onChange={searching} />
              <Table
                loading={tableLoading}
                pagination={false}
                rowKey={(i) => i._id}
                columns={tableCheckBoxItems({ selectedItems, addItem })}
                dataSource={items}
              />
              <Row justify="space-between" gutter={24} className="top-2">
                <Button type="primary" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button type="primary" onClick={onStep3}>
                  Next
                </Button>
              </Row>
            </>
          ) : (
            <Form
              name="nest-messages"
              onFinish={onFinish}
              onValuesChange={(v, values) => setForm(values)}
              defaultValue={form}
              validateMessages={validateMessages}
            >
              <Space
                direction="vertical"
                size={"large"}
                style={{ width: "100%" }}
              >
                <Form.List name="items" initialValue={form?.items}>
                  {(feilds) => {
                    return feilds.map((feild, index) => {
                      var item = (form?.items && form.items[index]) || {};
                      var dispItem = item?.item || item;
                      var props = {
                        dispItem,
                        feild,
                        item,
                        totalPrice,
                        feilds,
                        index,
                        getHistory,
                        setPrice,
                      };
                      return <ListRow {...props} />;
                    });
                  }}
                </Form.List>
                <Row gutter={24}>
                  <Col span={3} offset={21}>
                    <b>Grand Total:</b>
                    <InputNumber
                      value={state.grandTotal}
                          onChange={(e) => onchange("grandTotal", e)}
                    />
                  </Col>
                </Row>
                <Form.Item>
                  <Row justify="space-between" gutter={24} className="top-2">
                    <Button
                      loading={loader}
                      type="primary"
                      onClick={() => setStep(2)}
                    >
                      Back
                    </Button>
                    <div>
                      <Button
                        onClick={() => setSummary(form.items)}
                        type="primary"
                        style={{ marginRight: 20 }}
                      >
                        See Summary
                      </Button>
                      <Button loading={loader} type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form.Item>
              </Space>
            </Form>
          )}
          {summary.length > 0 && (
            <PurchaseSummary
              items={summary?.map((i, ind) => ({
                ...i,
                totalPrice: totalPrice[ind],
              }))}
              visible={true}
              data={state}
              onClose={() => setSummary([])}
            />
          )}
          {history.length > 0 && (
            <ItemPurchaseHistory
              items={history}
              visible={history.length > 0 ? true : false}
              onClose={() => setHistory([])}
            />
          )}
        </Space>
      </Modal>
    </>
  );
};
