import React, { useState } from "react";
import { Modal, Form, Input, TreeSelect, Button } from "antd";
import Password from "antd/lib/input/Password";
import { postMethod } from "../../api/functions";
import { ShowMessage } from "../toast";

/* eslint-disable no-template-curly-in-string */

const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const CompanyForm = ({ visible, onClose, title, user, data }) => {
  const [loader, setLoader] = useState(false);
  const onFinish = async (values) => {
    setLoader(true);
    const res = await postMethod(
      data ? "users/update" : user ? "users/signup" : "users/admin-signup",
      { ...values, _id: data?._id }
    );
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };

  return (
    <>
      <Modal
        title={title ?? "Add Company"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"username"}
            initialValue={data?.username}
            label="Username"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={data ? true : false} />
          </Form.Item>
          <Form.Item
            name={"name"}
            initialValue={data?.name}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"email"}
            initialValue={data?.email}
            label="Email"
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={data?.address}
            name={"address"}
            label="Address"
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={data?.contact}
            name={"contact"}
            label="Contact"
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={data?.license}
            name={"license"}
            label="License"
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={data?.discription}
            name={"discription"}
            label="Discription"
          >
            <Input.TextArea row={10} />
          </Form.Item>
          <Form.Item
            initialValue={data?.address}
            name={"macAddresses"}
            label="Mac Addresses"
          >
            <Input />
          </Form.Item>
          {!data && (
            <Form.Item
              rules={[
                {
                  required: true,
                  min: 6,
                },
              ]}
              name={"password"}
              label="Password"
            >
              <Password />
            </Form.Item>
          )}
          {user && (
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name={"privileges"}
              label="Privileges"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear
                multiple
                treeDefaultExpandAll
              >
                {["item", "manufecturer", "supplier", "purchase", "sale"].map(
                  (i) => (
                    <TreeNode value={i} title={i} />
                  )
                )}
              </TreeSelect>
            </Form.Item>
          )}
          <Form.Item>
            <Button loading={loader} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
