import { Alert } from "antd";
import axios from "axios";
import { ShowMessage as showMessage } from "../components/toast";
import { BASE_URL } from "./config";
import download from 'downloadjs';

const headers = () => {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + user?.token,
  };
};
export const postMethod = async (url, body) => {
  try {
    console.log(BASE_URL + url);
    const res = await axios.post(BASE_URL + url, body, {
      headers: headers(),
    });
    if (res?.data?.success) {
      return res?.data;
    } else {
      showMessage(res?.data?.message || "Network Error");
      return { success: false };
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
      showMessage("Unauthorized");
      window.location.href = "auth/";
    } else {
      showMessage(error?.response?.data?.message || "Network Error");
      return { success: false };
    }
  }
};
export const putMethod = async (url, body) => {
  try {
    const res = await axios.put(BASE_URL + url, body, {
      headers: headers(),
    });
    if (res?.data?.success) {
      return res?.data;
    } else {
      showMessage(res?.data?.message || "Network Error");
      return { success: false };
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      showMessage("Unauthorized");
      window.location.href = "auth/";
    } else {
      showMessage(error?.response?.data?.message || "Network Error");
      return { success: false };
    }
  }
};
export const getMethod = async (url, params, responseType = "json") => {
  try {
    const res = await axios.get(BASE_URL + url, {
      params,
      headers: headers(),
      responseType: responseType,
    });
    if (responseType == "blob") {
      return download(res.data, `itemstockreport.xlsx`, 'text/xlsx');
    }
    if (res?.data?.success) {
      return res?.data;
    } else {
      showMessage(res?.data?.message || "Network Error");
      return { success: false };
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.removeItem("user");
      showMessage("Unauthorized");
      window.location.href = "auth/";
    } else {
      showMessage(error?.response?.data?.message || "Network Error");
      return { success: false };
    }
  }
};
export const patchMethod = async (url, body) => {
  try {
    const res = await axios.patch(BASE_URL + url, body, {
      headers: headers(),
    });
    if (res?.data?.success) {
      return res?.data;
    } else {
      showMessage(res?.data?.message || "Network Error");
      return { success: false };
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      showMessage("Unauthorized");
      window.location.href = "auth/";
    } else {
      showMessage(error?.response?.data?.message || "Network Error");
      return { success: false };
    }
  }
};
