import { Space } from "antd";

export const itemCategoryTable = ({ onUpdate }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (discount) =>
      discount ? "Discount Available" : "Discount Not Available",
  },

  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onUpdate(record)}>Update</a>
      </Space>
    ),
  },
];
