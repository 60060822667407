import {
  Col,
  Divider,
  Row,
  Select,
  Table,
  DatePicker,
  InputNumber,
  TreeSelect,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod } from "../../api/functions";
import { purchaseOrderTable } from "../../components/tables/ItemTable";

const { RangePicker } = DatePicker;
const { TreeNode } = TreeSelect;
function PurchaseOrder(params) {
  const [menufecturer, setMenufecturer] = useState([]);
  const [supplier, setSuppliers] = useState(null);
  const menufecturers = useSelector((state) => state.meta.menufecturers);
  const suppliers = useSelector((state) => state.meta.suppliers);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    days: "10",
  });
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState((s) => ({
      ...s,
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    }));
  };
  useEffect(() => {
    getData();
  }, [menufecturer, state, supplier]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("items/purchase-order", {
      menufecturer,
      supplier,
      ...state,
    });
    if (res?.success) {
      setData(res.data);
    }
    setLoader(false);
  };
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Selet menufecturer</label>
          <TreeSelect
            showSearch
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Please select"
            allowClear
            disabled={supplier ? true : false}
            multiple
            treeDefaultExpandAll
            filterTreeNode={(value, node) =>
              node.title.toLowerCase().match(value)
            }
            value={menufecturer}
            onChange={(e) => setMenufecturer(e)}
          >
            {menufecturers.map((i) => (
              <TreeNode value={i._id} title={i.name} />
            ))}
          </TreeSelect>
        </Col>
        <Col span={12}>
          <label>Select Range</label>
          <br />
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={setDate}
            style={{ width: "100%" }}
            picker={"daily"}
          />
        </Col>
        <Col span={12}>
          <label>Select Supplier</label>
          <br />
          <Select
            showSearch
            value={supplier}
            onChange={(e) => setSuppliers(e)}
            style={{ width: "100%" }}
            placeholder="Please select"
            allowClear
            filterOption={(value, node) =>
              node.children.toLowerCase().match(value)
            }
          >
            {suppliers.map((i) => (
              <Select.Option value={i._id}>{i.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <label>Select Number Of Days</label>
          <br />
          <InputNumber
            value={state.days}
            onChange={(days) => setState((s) => ({ ...s, days }))}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={purchaseOrderTable()}
        dataSource={data}
      />
    </div>
  );
}

export default PurchaseOrder;
