import React, { useState } from "react";
import { Col, Modal, Row, Table } from "antd";
import { purchaseSummaryTable } from "../tables/PurchaseTable";
import { useSelector } from "react-redux";
import { itemHistoryTable } from "../tables/ItemTable";

/* eslint-disable no-template-curly-in-string */

export const ItemPurchaseHistory = ({ visible, onClose, items }) => {
  console.log({ visible, onClose, items });
  return (
    <>
      <Modal
        title={"Item History"}
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
        width={"70vw"}
        cancelText="Close"
      >
        <Table
          pagination={false}
          rowKey={(i) => i._id}
          columns={itemHistoryTable()}
          dataSource={items}
        />
      </Modal>
    </>
  );
};
