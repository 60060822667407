import React, { useState } from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import routes from "../../routes";
import { Layout, Menu, Breadcrumb, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

function Main({}) {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);
  var admin = localStorage.getItem("admin");
  admin = JSON.parse(admin);
  const getFilteredRoutes = () => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    var arr = [];
    switch (user.type) {
      case "super-admin":
        return (arr = routes.filter((i) => i.superAdmin));
      case "admin":
        return (arr = routes.filter((i) => i.user));
      case "user":
        return (arr = routes.filter(
          (i) =>
            i.user && i.privileges.some((pri) => user.privileges.includes(pri))
        ));
      default:
        return (arr = []);
    }
  };
  const getFilteredRoutesMap = () => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    var match = {
      users: ["/companies", "/users"],
      basic: [
        "/customers",
        "/accounts",
        "/account-categories",
        "/Item",
        "/item-category",
        "/Manufacturer",
        "/Supplier",
      ],
      transections: [
        "/vouchers",
        "/journal",
        "/ledger",
        "/trail-balance",

      ],
      reports: [
        "/sale-history",
        "/stock-history",
        "/sale-report",
        "/cash-activity-report",
        "/stock-activity-report",
      ],
      sale: ["/sale", "/sale-return"],
      purchase: ["/Purchase", "/Purchase-return",
        "/purchase-order",],
    };
    var arr = [];
    var obj = {
      users: { name: "Users", routes: [] },
      basic: { name: "Basic Data", routes: [] },
      reports: { name: "Reports", routes: [] },
      transections: { name: "Transections", routes: [] },
      sale: { name: "Sale", routes: [] },
      purchase: { name: "Purchase", routes: [] },
    };
    switch (user.type) {
      case "super-admin":
        arr = routes.filter((i) => i.superAdmin);
        break;
      case "admin":
        arr = routes.filter((i) => i.user);
        break;
      case "user":
        arr = routes.filter(
          (i) =>
            i.user && i.privileges.some((pri) => user.privileges.includes(pri))
        );
        break;
      default:
        arr = [];
    }
    arr.map((route) => {
      Object.keys(match).map((keys) => {
        if (match[keys].includes(route.path)) {
          obj[keys].routes.push(route);
        }
      });
    });
    return obj;
  };
  const [selectedKey, setSelectedKey] = useState(getFilteredRoutes()[0].name);
  const [selectedKey1, setSelectedKey1] = useState(getFilteredRoutes()[0].name);

  const menu = (routes) => (
    <Menu>
      {routes.map((item) => {
        return (
          <Link key={item.name} to={item.layout + item.path}>
            <Menu.Item
              style={
                selectedKey1 == item.name && {
                  backgroundColor: "#1890FF",
                }
              }
              onClick={() => setSelectedKey1(item.name)}
            >
              {item.name}
            </Menu.Item>
          </Link>
        );
      })}
    </Menu>
  );
  const getRoutes = () => {
    return getFilteredRoutes().map((prop, key) => {
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={prop.name}
          />
        );
      } else {
        return null;
      }
    });
  };
  const logout = () => {
    localStorage.removeItem("user");
    window.location.href = "/auth";
  };
  var username = admin?.name
  if (user?.name && admin?.name != user?.name)
    username = username + " | " + user?.name
  return (
    <Layout className="layout main-content">
      <Header style={{ padding: 0, margin: 0 }}>
        <div>
          <Menu
            theme="dark"
            mode="horizontal"
            className="row between"
            selectedKeys={[selectedKey]}
          >
            {/* <span style={{ width: "90%" }}> */}
            {/* {Object.values(getFilteredRoutesMap()).map((item) => {
              return (
                <Link key={item.name} to={item.layout + item.path}>
                  <Menu.Item
                    style={
                      selectedKey == item.name && {
                        backgroundColor: "#1890FF",
                      }
                    }
                    onClick={() => setSelectedKey(item.name)}
                  >{`${item.name}`}</Menu.Item>
                </Link>
              );
            })} */}
            {/* </span> */}
            {Object.values(getFilteredRoutesMap()).map((value) => {
              return (
                <Menu.Item
                  style={
                    selectedKey == value.name && {
                      backgroundColor: "#1890FF",
                    }
                  }
                  onClick={() => setSelectedKey(value.name)}
                >
                  <Dropdown className="" overlay={menu(value.routes)}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {value.name} <DownOutlined />
                    </a>
                  </Dropdown>
                </Menu.Item>
              );
            })}

            <Menu.Item onClick={logout}>{username} | Logout</Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content className="" style={{ padding: "0 10px" }}>
        <div style={{ minHeight: "82vh" }} className="site-layout-content">
          <Switch>
            {getRoutes()}
            <Redirect
              from={"/main/"}
              to={"/main" + getFilteredRoutes()[0].path}
            />
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Developed By{" "}
        <a target={"_blank"} href="https://github.com/abdullah2011-gif">
          @abdullah2011-gif
        </a>{" "}
        &{" "}
        <a target={"_blank"} href="https://github.com/abdullah2011-gif">
          @Saqlain
        </a>
      </Footer>
    </Layout>
  );
}

export default Main;
