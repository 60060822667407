import React, { useEffect, useState } from "react";
import { Table, Button, Divider } from "antd";
import { CompanyForm } from "../../components/Modal/CompanyForm";
import { companyColumns } from "../../components/tables/companies";
import { getMethod } from "../../api/functions";

function Users(params) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState(null);
  const [loader, setLoader] = useState(true);

  const onUpdate = (item) => {
    setItem(item);
    setShow(true);
  };
  
  useEffect(() => {
    getData();
  }, []);
  
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("users/all");
    if (res?.success) setData(res?.data);
    setLoader(false);
  };
  console.log({ item });
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary"> Add New </Button>
      <Divider />
      
      <Table
        loading={loader}
        pagination={false}
        columns={companyColumns({ onUpdate })}
        dataSource={data} 
        />


      {show && (
        <CompanyForm
          title="Add User"
          user
          data={item}
          visible={show}
          onClose={() => {
            getData();
            setShow(false);
            setItem(null);
          }}
        />
      )}
    </div>
  );
}

export default Users;
