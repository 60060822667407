import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber, Row, TreeSelect
} from "antd";
import moment from "moment";
import React from "react";

/* eslint-disable no-template-curly-in-string */
const { TreeNode } = TreeSelect;
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
const PurchaseAddRow = ({
    dispItem,
    feild,
    item,
    update,
    totalPrice,
    feilds,
    index,
    getHistory,
    setPrice,
    isAdding
}) => {
    return (
        <>
            <Row gutter={24} justify="space-between">
                <Col span={4}>
                    <h3>{dispItem.name + "-" + dispItem.packing + "(Packing)"}</h3>
                    <Form.Item
                        name={isAdding ? "_id" : [feild.name, "_id"]}
                        label="_id:"
                        hidden
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input defaultValue={item._id} />
                    </Form.Item>
                    <Button
                        onClick={() => getHistory(item._id)}
                        type="primary"
                        size="small"
                        style={{ marginLeft: 10 }}
                    >
                        Check
                    </Button>
                </Col>
                <Col span={8}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "batch" : [feild.name, "batch"]}
                        initialValue={item.batch || 0}
                        label="Batch No:"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "expiry" : [feild.name, "expiry"]}
                        initialValue={item.expiry ? item.expiry : moment().add(3, "years")}
                        label="Expiry Date:"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <DatePicker
                            format={"DD/MM/YYYY"}
                            locale="UCT/GMT+5"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} justify="space-between">
                <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "quantity" : [feild.name, "quantity"]}
                        label="QTY"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber defaultValue={item.quantity} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "purchasePrice" : [feild.name, "purchasePrice"]}
                        label="Purchase Price"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber value={item.purchasePrice} />
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "totalSalePrice" : [feild.name, "totalSalePrice"]}
                        label="Sale Price"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber value={item.totalSalePrice} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "discount" : [feild.name, "discount"]}
                        label="Discount"
                        initialValue={item?.discountInPercent || item.discount}
                        rules={[
                            {
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber
                            defaultValue={item?.discountInPercent || item.discount || 0}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "extrawQuantity" : [feild.name, "extrawQuantity"]}
                        label="Ex QTY"
                        rules={[
                            {
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber defaultValue={item.extraQuantity || 0} />
                    </Form.Item>
                </Col>
                {!isAdding && <Col span={3}>
                    <Form.Item
                        {...feild}
                        name={isAdding ? "totalPrice" : [feild.name, "totalPrice"]}
                        shouldUpdate={(a, b, info) => setPrice(a, b, info, index)}
                        label="Total:"
                    >
                        {console.log(totalPrice[index])}
                        <InputNumber disabled={true} value={totalPrice[index]} />
                    </Form.Item>
                </Col>}
            </Row>
            {index < feilds.length - 1 && <Divider />}
        </>
    );
};
export default PurchaseAddRow