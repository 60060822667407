import { Col, DatePicker, Divider, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";
import { cashActivityTable } from "../../components/tables/account";

const { RangePicker } = DatePicker;
function CashActivityReport({ }) {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    q: "",
  });
  const [data, setData] = useState({
    openingBalance: 0,
    data: [],
    totalBalance: 0,
    totalPaid: 0,
    totalReceived: 0,
  });
  useEffect(() => {
    getData();
  }, [state]);
  const getData = async (params) => {
    setLoader(true)
    var res = await getMethod("voucher/activity-report", { ...params, ...state });
    if (res?.success) {
      setData(res)
    }
    setLoader(false)
  };
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };


  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Select Range</label>
          <br />
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={setDate}
            style={{ width: "100%" }}
            picker={"daily"}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        <Col offset={20}>{`Previous Balance: ${data.openingBalance.toFixed(2)}`}</Col>
      </Row>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={cashActivityTable()}
        dataSource={[...data.data, {
          remarks: "Total:",
          paid: data.totalPaid,
          received: data.totalReceived,
          balance: data.totalBalance
        }]}
      />

    </div >
  );
}

export default CashActivityReport;
