import { Space } from "antd";
import moment from "moment";

export const saleTable = ({ onUpdate, onView, onAccountPress }) => [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t) => (
      <p>{moment(t).isValid() ? moment(t).format("YYYY-MM-DD") : "-"}</p>
    ),
  },
  {
    title: "Invoice #",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
  },
  {
    title: "Type",
    dataIndex: "saleType",
    key: "saleType",
  },
  {
    title: "Customer",
    dataIndex: "customerId",
    render: (t) => <p>{t ? t.name : "-"}</p>,
  },
  {
    title: "Total",
    dataIndex: "grandTotal",
    key: "grandTotal",
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onView(record)}>View</a>
      </Space>
    ),
  },
];
export const saleReturnTable = ({ onUpdate, onView, onAccountPress }) => [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t) => (
      <p>{moment(t).isValid() ? moment(t).format("YYYY-MM-DD") : "-"}</p>
    ),
  },
  {
    title: "Invoice #",
    dataIndex: "returnInvoiceNo",
    key: "returnInvoiceNo",
  },
  {
    title: "Type",
    dataIndex: "saleType",
    key: "saleType",
  },
  {
    title: "customer",
    dataIndex: "customerId",
    render: (t) => <p>{t ? t.name : "-"}</p>,
  },
  {
    title: "Total",
    dataIndex: "grandTotal",
    key: "grandTotal",
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onView(record)}>View</a>
      </Space>
    ),
  },
];

export const saleSummaryTable = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (cat, record) => record?.item?.name || cat || "-",
  },
  {
    title: "Quantity",
    dataIndex: "packing",
    key: "packing",
    render: (item, record) => item || "-",
  },

  {
    title: "Sale Price",
    dataIndex: "salePrice",
    key: "salePrice",
    render: (item, record) => item || "-",
  },
  {
    title: "Discount Price",
    dataIndex: "discountPrice",
    key: "discountPrice",
    render: (discountInPercent, record) => discountInPercent ?? "-",
  },
  {
    title: "Total",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (cat) => cat || "-",
  },
];
export const saleSummaryReceiptTable = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (cat, record) => record?.item?.name || cat || "-",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (item, record) => item || "-",
  },

  {
    title: "Sale Price",
    dataIndex: "salePrice",
    key: "salePrice",
    render: (item, record) => item || "-",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (discountInPercent, record) => discountInPercent ?? "-",
  },
  {
    title: "Total",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (cat) => cat || "-",
  },
];
export const saleStatTable = () => [
  {
    title: "Date",
    dataIndex: "_id",
    key: "_id",
    render: (cat, record) => cat || "-",
  },
  {
    title: "Total Sale Amount",
    dataIndex: "priceWithDiscount",
    key: "priceWithDiscount",
    render: (cat) => cat || "-",
  },
  {
    title: "Discount Price",
    dataIndex: "discountPrice",
    key: "discountPrice",
    render: (item, record) => item || "-",
  },
  {
    title: "Grand Total",
    dataIndex: "grandTotal",
    key: "grandTotal",
    render: (item, record) => item || "-",
  },
];

export const saleHistoryTable = () => [
  {
    title: "Date",
    dataIndex: "_id",
    key: "_id",
    render: (cat, record) => cat || "-",
  },
  {
    title: "Total Sale Amount",
    dataIndex: "totalSale",
    key: "totalSale",
    render: (cat) => cat?.toFixed(2) || "-",
  },
  {
    title: "Discount Price",
    dataIndex: "discount",
    key: "discount",
    render: (item, record) => item?.toFixed(2) || "-",
  },
  {
    title: "Gross Sale",
    dataIndex: "grossSale",
    key: "grossSale",
    render: (item, record) => item?.toFixed(2) || "-",
  },
  {
    title: "Sale Return",
    dataIndex: "totalSaleReturn",
    key: "totalSaleReturn",
    render: (item, record) => item?.toFixed(2) || "-",
  },
  {
    title: "Net Sale",
    dataIndex: "netSale",
    key: "netSale",
    render: (item) => {
      return Number(item)?.toFixed(2) || "-"
    }
  },
  {
    title: "Avg. Cost Of Sale",
    dataIndex: "avgCostOfSale",
    key: "avgCostOfSale",
    render: (value, record) => {
      return Number(value)?.toFixed(2) || "-"
    }
  },
  {
    title: "G/P Percentage",
    dataIndex: "gpPercentage",
    key: "costOfSale",
    render: (item, record) => {
      return item + "%"
    },
  },
  {
    title: "G/P Amount",
    dataIndex: "grandTotalAmount",
    key: "costOfSale",
    render: (item, record) => Number(item)?.toFixed(2) || "-",
  },
];
