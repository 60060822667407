import React, { useEffect, useState } from "react";
import { Modal, Form, Input, TreeSelect, Button, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { ShowMessage } from "../toast";
import { postMethod, putMethod } from "../../api/functions";

/* eslint-disable no-template-curly-in-string */

const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const ItemForm = ({ visible, onClose, item, edit }) => {
  const [data, setData] = useState(null);
  const menufecturers = useSelector((state) => state.meta.menufecturers);
  const suppliers = useSelector((state) => state.meta.suppliers);
  const categories = useSelector((state) => state.meta.itemCategories);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (item) {
      setData({
        ...item,
        menufecturers: item.menufecturers.map((i) => i._id),
        suppliers: item.suppliers.map((i) => i._id),
      });
    } else setData(null);
  }, item);
  const onFinish = async (values) => {
    setLoader(true);
    let res;
    if (edit)
      res = await putMethod("items/detail", { ...values, _id: data._id });
    else res = await postMethod("items/detail", values);
    if (res?.success) {
      ShowMessage("Success", "success");
      onClose();
    }
    setLoader(false);
  };
  return (
    <>
      <Modal
        title="Add Item"
        visible={visible}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        {(!edit || (edit && data)) && (
          <Form
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "array",
                },
              ]}
              initialValue={data?.menufecturers || []}
              name={"menufecturers"}
              label="Manufecturers"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear
                multiple
                treeDefaultExpandAll
                filterTreeNode={(value, node) => node.title.toLowerCase().match(value)}
              >
                {menufecturers.map((i) => (
                  <TreeNode value={i._id} title={i.name} />
                ))}
              </TreeSelect>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "array",
                },
              ]}
              initialValue={data?.suppliers || []}
              name={"suppliers"}
              label="Suppliers"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear
                filterTreeNode={(value, node) => node.title.toLowerCase().match(value)}
                multiple
                treeDefaultExpandAll
              >
                {suppliers.map((i) => (
                  <TreeNode value={i._id} title={i.name} />
                ))}
              </TreeSelect>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={data?.category?._id}
              name={"category"}
              label="Category"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear

                filterTreeNode={(value, node) => node.title.toLowerCase().match(value)}
                treeDefaultExpandAll
              >
                {categories.map((i) => (
                  <TreeNode value={i._id} title={i.name} />
                ))}
              </TreeSelect>
            </Form.Item>
            <Form.Item
              name={"name"}
              label="Name"
              initialValue={data?.name || ""}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"packing"}
              label="Packing"
              initialValue={data?.packing || ""}
              rules={[
                {
                  required: true,
                  type: "number",
                },
              ]}
            >
              <InputNumber disabled={edit ? true : false} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, type: "number" }]}
              name={"purchasePrice"}
              initialValue={data?.purchasePrice || ""}
              label="Purchase Price"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, type: "number" }]}
              name={"salePrice"}
              initialValue={data?.totalSalePrice || ""}
              label="Sale Price"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item>
              <Button loading={loader} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};
