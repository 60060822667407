import { Space } from "antd";

export const companyColumns = ({ onUpdate }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
    ellipsis: "-",
    render: (text) => text ?? "-",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    ellipsis: "-",
    render: (text) => text ?? "-",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (text) => text ?? "-",
  },
  {
    title: "Status",
    dataIndex: "disabled",
    key: "disabled",
    render: (disabled) => (disabled ? "Disabled" : "Active"),
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => onUpdate(record)}>Update</a>
      </Space>
    ),
  },
];
