import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal } from "antd";
import { ManufacturerForm } from "../../components/Modal/ManufacturerForm";
import { manufacturerTable } from "../../components/tables/ManufacturerTable";
import { getMethod } from "../../api/functions";
import { useDispatch, useSelector } from "react-redux";
import { setDataAction } from "../../store/actions";

function Manufacturer(params) {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const data = useSelector((state) => state.meta.menufecturers);
  const dispatch = useDispatch();
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("menufecturer/detail");
    if (res?.success) dispatch(setDataAction({ menufecturers: res?.data }));
    setLoader(false);
  };
  const onUpdate = (item) => {
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        tableLayout="fixed"
        loading={loader}
        pagination={false}
        columns={manufacturerTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <ManufacturerForm
          visible={show}
          onClose={() => {
            setShow(false);
            getData();
          }}
        />
      )}
    </div>
  );
}

export default Manufacturer;
