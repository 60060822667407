import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TreeSelect,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "../../api/config";
import { getMethod, postMethod } from "../../api/functions";
import {
  tableCheckBoxItemsSale,
  tableCheckBoxItemsSaleReturn,
} from "../tables/ItemTable";
import { ShowMessage } from "../toast";
import { PurchaseSummary } from "./PurchaseSummary";
import { useReactToPrint } from "react-to-print";
import SalePrint from "../Form/SalePrint";

/* eslint-disable no-template-curly-in-string */
const { Option } = Select;
const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const SaleRow = ({
  dispItem,
  feild,
  item,
  setPrice,
  totalPrice,
  feilds,
  update,
  index,
}) => {
  const [stock, setStock] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getStock();
  }, []);
  const getStock = async () => {
    var res = await getMethod("items/stock", { item: item?._id });
    if (res?.success) {
      setStock(res.data);
    }
    setLoader(false);
  };
  if (loader)
    return (
      <>
        <div className="loader1" />
        {index < feilds.length - 1 && <hr />}
      </>
    );
  return (
    <>
      <Row gutter={24} justify="space-between">
        <Col span={3}>
          <h3>{dispItem.name + "-" + dispItem.packing + "(Packing)"}</h3>
        </Col>

        <Col span={3}>
          <Form.Item
            {...feild}
            name={[feild.name, "quantity"]}
            label={"QTY (" + item?.packing + ")"}
            rules={[
              {
                required: false,
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber defaultValue={update ? item.packing : item.quantity} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            {...feild}
            name={[feild.name, "salePrice"]}
            label="Sale Price"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber disabled={true} value={item.salePrice} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            {...feild}
            name={[feild.name, "discount"]}
            label={`Disc (${item?.discountInPercent || "no discount"})`}
            initialValue={item?.discountInPercent || item.discount}
            rules={[
              {
                required: false,
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber
              defaultValue={item?.discountInPercent || item.discount}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            {...feild}
            name={[feild.name, "totalPrice"]}
            shouldUpdate={(a, b, info) => setPrice(a, b, info, index)}
            label="Total:"
          >
            {console.log(totalPrice[index])}
            <InputNumber disabled={true} value={totalPrice[index]} />
          </Form.Item>
        </Col>
      </Row>
      {index < feilds.length - 1 && <hr />}
    </>
  );
};
export const SaleReturnForm = ({ visible, onClose, update }) => {
  const componentRef = useRef();
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({});
  const customers = useSelector((state) => state.meta.customers);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [summary, setSummary] = useState(null);
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [price, setOkPrice] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [invoiceSearch, setInvoiceSearch] = useState("");
  const [state, setState] = useState({
    grandTotal: "",
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setSummary(null)
    }
  });
  const getInvoiceNo = debounce(async (value) => {
    var res = await getMethod("sale/detail", {
      invoiceNo: value,
    });
    if (res?.success) setInvoiceData(res.data);
  }, 300);
  const onInvoiceSearch = (value) => {
    getInvoiceNo(value);
    setInvoiceSearch(value);
  };
  useEffect(() => {
    getItems();
    if (update) {
      setTimeout(() => {
        setTotalPrice(update.items.map((i) => i.totalPrice));
      }, 600);
      setForm({
        items: update.items.map((i) => ({ ...i, expiry: moment(i.expiry) })),
      });
      setStep(3);
    } else setStep(1);
  }, [update]);
  const onFinish = async (values) => {
    setLoader(true);
    var items = values.items || [];
    items = items.map((i, ind) => ({
      ...i,
      totalPrice: totalPrice[ind],
      priceWithDiscount: price[ind],
      discount: i.discount ? i.discount : 0,
    }));
    let res;
    res = await postMethod("sale/return", {
      items,
      saleType,
      customerId,
      grandTotal: state.grandTotal,
      invoiceNo,
      totalPrice: price.reduce((sum, i) => i + sum, 0),
    });
    if (res?.success) {
      reset();
      setSummary({ ...res.data, items });
      handlePrint();
    }
    setLoader(false);
  };
  const reset = () => {
    setStep(1);
    setInvoiceNo(null);
    setSummary(null);
    setTotalPrice([]);
    setOkPrice([]);
    setCustomerId("");
    setSelectedItems([]);
    setSearch("");
    onchange("grandTotal", "");
    setSaleType("cash");
    setForm({});
  };
  const onchange = (key, val) => {
    setState((s) => ({ ...s, [key]: val }));
  };
  const getItems = async (params = {}) => {
    try {
      setTableLoading(true);
      setStep(1);
      var res = await getMethod("items/detail", {
        ...params,
      });
      if (res?.success) {
        setItems(res.data);
      }
      setTableLoading(false);
    } catch (error) {
      ShowMessage(error?.message);
    }
  };
  const addItem = (val, item) => {
    setSelectedItems((items) => {
      if (val) return [...items, item];
      else return items.filter((i) => i._id != item._id);
    });
  };
  const searching = (e) => {
    setSearch(e.target.value);
    getItems({ q: e.target.value });
  };
  const onStep3 = () => {
    var items
    if (form && form?.items) {
      setForm((fo) => {
        var temp = [...fo.items];
        items = [
          ...selectedItems.map((i) => {
            var item = temp.find((it) => it._id == i._id);
            if (item) return item;
            else return i;
          }),
        ];
        fo.items = [...items];
        return fo;
      });
    } else if (!form?.items) setForm((f) => ({ ...f, items: selectedItems }));
    setStep(3);
    if (items) {
      setTotalPrice(totalPrice.splice(0, items.length))
      setOkPrice(price.splice(0, items.length))
    }
  };
  const setPrice = (prevValues, currentValues, info, index) => {
    if (info.source == "internal") {
      var prevobj = prevValues.items[index];
      var currentObj = currentValues.items[index];
      var { quantity: pquanity, discount: pdiscount } = prevobj;
      var {
        salePrice: csalePrice,
        quantity: cquanity,
        discount: cdiscount,
      } = currentObj;
      if (!cdiscount && cquanity) cdiscount = 0;
      if (
        typeof cquanity == "number" &&
        typeof cdiscount == "number" &&
        (pquanity != cquanity || pdiscount != cdiscount)
      ) {
        var price = csalePrice * cquanity;
        var dis = (cdiscount * price) / 100;
        var totalPrice = price - dis;
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = price;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = totalPrice;
          return [...temp];
        });
        return true;
      } else if (!cquanity || (!cdiscount && pquanity && pdiscount)) {
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (totalPrice.length > 0)
      onchange(
        "grandTotal",
        totalPrice.reduce((i, sum) => i + sum)
      );
  }, [totalPrice]);
  useEffect(() => {
    if (invoiceNo) {
      var data = invoiceData.find((i) => i._id == invoiceNo);
      setCustomerId(data.customerId?._id);
      setInvoice(data);
      setSelectedItems([]);
    } else {
      setCustomerId("");
    }
  }, [invoiceNo]);
  const [saleType, setSaleType] = React.useState("cash");
  console.log({ form })
  return (
    <>
      <Modal
        title="Sale Return"
        visible={visible}
        centered
        maskClosable={false}
        width={"90vw"}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Space style={{ width: "100%" }} direction="vertical" size={"large"}>
          {step == 1 ? (
            <>
              <Row gutter={24}>
                <Col span={8}>
                  <label>Invoice #</label>
                  {/* <select>
                    <option>
                      <input value={""} />
                    </option>
                    {invoiceData.map((i) => (
                      <option value={i._id}>{i.invoiceNo}</option>
                    ))}
                  </select> */}
                  <Select
                    showSearch
                    searchValue={invoiceSearch}
                    onSearch={onInvoiceSearch}
                    value={invoiceNo}
                    filterOption={false}
                    onChange={setInvoiceNo}
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    // options={invoiceData.map((i) => ({
                    //   label: String(i.invoiceNo),
                    //   value: i._id,
                    // }))}
                    allowClear
                  >
                    {invoiceData.map((i) => {
                      return <Option value={i._id}>{i.invoiceNo}</Option>;
                    })}
                  </Select>
                </Col>

                <Col span={8}>
                  <label>Select Customers</label>
                  <Select
                    showSearch
                    disabled={invoiceNo ? true : false}
                    value={customerId}
                    onChange={(e) => setCustomerId(e)}
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    allowClear
                  >
                    {customers.map((i) => (
                      <Option value={i._id}>{i.name}</Option>
                    ))}
                  </Select>
                </Col>
                {customerId && (
                  <Col span={8}>
                    <label>Sale Type</label>
                    <Select
                      defaultValue="cash"
                      style={{ width: "100%" }}
                      value={saleType}
                      onChange={setSaleType}
                    >
                      <Option value="cash">Cash</Option>
                      <Option value="credit">Credit</Option>
                    </Select>
                  </Col>
                )}
              </Row>
              <Input placeholder="Search" value={search} onChange={searching} />
              <Table
                loading={tableLoading}
                pagination={false}
                rowKey={(i) => i._id}
                columns={
                  invoiceNo
                    ? tableCheckBoxItemsSaleReturn({ selectedItems, addItem })
                    : tableCheckBoxItemsSale({ selectedItems, addItem })
                }
                dataSource={invoiceNo ? invoice?.items || [] : items}
              />
              <Row justify="space-between" gutter={24} className="top-2">
                <Button type="primary" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button type="primary" onClick={onStep3}>
                  Next
                </Button>
              </Row>
            </>
          ) : (
            <Form
              name="nest-messages"
              onFinish={onFinish}
              onValuesChange={(v, values) => setForm(values)}
              defaultValue={form}
              validateMessages={validateMessages}
            >
              <Form.List name="items" initialValue={form?.items}>
                {(feilds) => {
                  return feilds.map((feild, index) => {
                    var item = (form?.items && form.items[index]) || {};
                    var dispItem = item?.item || item;
                    const props = {
                      dispItem,
                      feild,
                      item,
                      setPrice,
                      totalPrice,
                      feilds,
                      update,
                      index,
                    };
                    return <SaleRow {...props} />;
                  });
                }}
              </Form.List>
              <Row gutter={24}>
                <Col span={3} offset={21}>
                  <b>Grand Total:</b>
                  <InputNumber
                    value={state.grandTotal}
                    onChange={(e) => onchange("grandTotal", e)}
                  />
                </Col>
              </Row>
              <Form.Item>
                <Row justify="space-between" gutter={24} className="top-2">
                  {!update ? (
                    <Button
                      loading={loader}
                      type="primary"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                  ) : (
                    <div />
                  )}
                  <div>
                    <Button loading={loader} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Space>
        {summary && <>
          <div style={{ display: "none" }}>
            <SalePrint data={{ ...summary, invoiceNo: summary.returnInvoiceNo, customerId: { name: "SALE RETURN" } }} ref={componentRef} />
          </div>
        </>}
      </Modal>
    </>
  );
};
