import {
  Col,
  Divider,
  Row,
  Select,
  Table,
  Button,
  Checkbox,
  Form,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod, postMethod } from "../../api/functions";
import SecurityModal from "../../components/Modal/SecurityModal";
import { itemStockActivityReport } from "../../components/tables/ItemTable";
import Adjesment from "./Adjesment";

const { Option } = Select;
function StockActivityReport(params) {
  const [menufecturer, setMenufecturer] = useState();
  const menufecturers = useSelector((state) => state.meta.menufecturers);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [code, setCode] = useState(null);

  const [show, setShow] = useState(null);

  const onUpdate = (item) => {
    setShow(item);
    // setCode(item);
  };

  useEffect(() => {
    getData();
  }, [menufecturer]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await postMethod("items/stock-activity-report", { menufecturer: menufecturer == "All" ? null : menufecturer, menufecturers: menufecturer == "All" ? menufecturers.map(i => i._id) : null });
    if (res?.success) {
      setData(res.data);
    }
    setLoader(false);
  };
  const total = data.reduce(
    (returningItem, item) => {
      // returningItem.totalQuantity += item.totalQuantity
      // returningItem.salePrice += item.salePrice
      returningItem.totalPrice += item.totalPrice;
      return returningItem;
    },
    {
      name: "Total: ",
      totalQuantity: "-",
      salePrice: "-",
      packing: "-",
      totalPrice: 0,
    }
  );

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Selet menufecturer</label>
          <Select
            showSearch
            value={menufecturer}
            onChange={(e) => setMenufecturer(e)}
            style={{ width: "100%" }}
            placeholder="Please select"
            filterOption={(value, node) =>
              node.children.toLowerCase().match(value)
            }
            allowClear
          >
            <Option value={"All"}>All</Option>
            {menufecturers.map((menu) => (
              <Option value={menu._id}>{menu.name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={itemStockActivityReport({ onUpdate })}
        dataSource={[...data, { ...total, noUpdate: true }]}
      />
      {code && <SecurityModal
        visible={code ? true : false}
        onClose={() => setCode(null)}
        onSuccess={() => {
          setShow(code);
          setCode(null)
        }} adjustment={true} />}

      {show && (
        <Adjesment
          visible={show ? true : false}
          data={show}
          onClose={() => {
            getData()
            setShow(null);
          }}
        />)}
    </div>
  );
}

export default StockActivityReport;
