import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal } from "antd";
import { ManufacturerForm } from "../../components/Modal/ManufacturerForm";
import { manufacturerTable } from "../../components/tables/ManufacturerTable";
import { getMethod } from "../../api/functions";
import { useDispatch, useSelector } from "react-redux";
import { setDataAction } from "../../store/actions";
import { itemCategoryTable } from "../../components/tables/itemCategoriesTable";
import { ItemCategoryForm } from "../../components/Modal/itemCategoryForm";

function ItemCategories({}) {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [update, setUpdate] = useState(null);
  const data = useSelector((state) => state.meta.itemCategories);
  const dispatch = useDispatch();
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("item-category/detail");
    if (res?.success) dispatch(setDataAction({ itemCategories: res?.data }));
    setLoader(false);
  };
  const onUpdate = (item) => {
    setUpdate(item);
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        tableLayout="fixed"
        loading={loader}
        pagination={false}
        columns={itemCategoryTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <ItemCategoryForm
          title="Add Item Category"
          visible={show}
          update={update}
          onClose={() => {
            setUpdate(null);
            setShow(false);
            getData();
          }}
        />
      )}
    </div>
  );
}

export default ItemCategories;
