import {
    Col,
    Form, InputNumber, Row,
    Select, TreeSelect
} from "antd";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";

/* eslint-disable no-template-curly-in-string */
const { Option } = Select;
const { TreeNode } = TreeSelect;
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
const SaleFormRow = ({
    dispItem,
    feild,
    item,
    setPrice,
    totalPrice,
    feilds,
    update,
    index, isAdding
}) => {
    const [stock, setStock] = useState([]);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        getStock();
    }, []);
    useEffect(() => {
        var { salePrice, quantity, discount } = feild
        if (!salePrice) salePrice = 0
        if (!quantity) quantity = 0
        if (!discount) discount = 0
        const price = salePrice * quantity
        var dis = (discount * price) / 100;
        var totalPrice = price - dis;
        setTotal(totalPrice)
    }, [feild.salePrice, feild.quantity, feild.discount])
    const getStock = async () => {
        var res = await getMethod("items/stock", { item: item?._id });
        if (res?.success) {
            setStock(res.data);
        }
        setLoader(false);
    };
    if (loader)
        return (
            <>
                <div className="loader1" />
                {index < feilds.length - 1 && <hr />}
            </>
        );
    else if (stock.length > 0) {
        var availableQuantity = stock.reduce(
            (sum, i) => i.quantity * i.packing + i.availablePacking + sum,
            0
        );
        return (
            <>
                <Row gutter={24} justify="space-between">
                    <Col span={3}>
                        <h3>{dispItem.name + "-" + dispItem.packing + "(Packing)"}</h3>
                    </Col>

                    <Col span={3}>
                        <Form.Item
                            {...feild}
                            name={isAdding ? "quantity" : [feild.name, "quantity"]}
                            label={"QTY (" + availableQuantity + ")"}
                            rules={[
                                {
                                    required: false,
                                    type: "number",
                                    min: 0,
                                    max: availableQuantity,
                                },
                            ]}
                        >
                            <InputNumber
                                autoFocus
                                defaultValue={update ? item.packing : item.quantity}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            {...feild}
                            name={isAdding ? "salePrice" : [feild.name, "salePrice"]}
                            label="Sale Price"
                            rules={[
                                {
                                    required: true,
                                    type: "number",
                                    min: 0,
                                },
                            ]}
                        >
                            <InputNumber value={item.salePrice} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            {...feild}
                            name={isAdding ? "discount" : [feild.name, "discount"]}
                            label="Discount"
                            initialValue={item?.discountInPercent || item.discount}
                            rules={[
                                {
                                    required: false,
                                    type: "number",
                                    min: 0,
                                },
                            ]}
                        >
                            <InputNumber
                                disabled={!item.category?.discount}
                                defaultValue={item?.discountInPercent || item.discount}
                            />
                        </Form.Item>
                    </Col>
                    {!isAdding && <Col span={3}>
                        <Form.Item
                            {...feild}
                            name={[feild.name, "totalPrice"]}
                            shouldUpdate={(a, b, info) => setPrice(a, b, info, index)}
                            label="Total:"
                        >
                            {console.log(totalPrice[index])}
                            <InputNumber disabled={true} value={totalPrice[index]} />
                        </Form.Item>
                    </Col>}
                    {isAdding && <Col span={3}>
                        {console.log({ total })}
                        <FormItemLabel label="Total:" />
                        <InputNumber disabled={true} value={(total)} />
                    </Col>}
                </Row>
                {index < feilds.length - 1 && <hr />}
            </>
        );
    } else
        return <h3>{dispItem.name + "-" + dispItem.packing + " Out Of Stock"}</h3>;
};

export default SaleFormRow