import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TreeSelect,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod, postMethod } from "../../api/functions";
import SaleFormRow from "../Form/SaleFormRow";
import SalePrint from "../Form/SalePrint";
import { tableCheckBoxItemsSale } from "../tables/ItemTable";
import { saleSummaryReceiptTable } from "../tables/SaleTable";
import { ShowMessage } from "../toast";
import { PurchaseSummary } from "./PurchaseSummary";
import { useReactToPrint } from "react-to-print";

/* eslint-disable no-template-curly-in-string */
const { Option } = Select;
const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const SaleForm = ({ visible, onClose, update }) => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({});
  const customers = useSelector((state) => state.meta.customers);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [summary, setSummary] = useState(null);
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [price, setOkPrice] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalItem, setModalItem] = useState(null)
  const [search, setSearch] = useState("");
  const [addingItem, setAddingItem] = useState({})
  const componentRef = useRef();
  const searchRef = useRef()
  const [state, setState] = useState({
    grandTotal: "",
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setSummary(null)
      if (searchRef.current) {
        searchRef.current.focus()
        setSearch("");
      }
    }
  });
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      if (step === 1) {
        const { key } = event;
        if (key == "ArrowRight") {
          onStep3()
        }
      }
    };
    // const focused = () => {
    //   console.log({ focused: true })
    // }
    window.addEventListener("keydown", handleUserKeyPress);
    // window.addEventListener("focus", focused);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
      // window.removeEventListener("focus", focused);
    };
  }, [step, selectedItems, form]);
  const getInvoiceNo = async () => {
    var res = await getMethod("sale/get-invoice-no");
    if (res?.success) setInvoiceNo(res.data);
  };
  useEffect(() => {
    if (!invoiceNo) getInvoiceNo();
  }, [invoiceNo]);
  useEffect(() => {
    getItems();
    if (update) {
      setTimeout(() => {
        setTotalPrice(update.items.map((i) => i.totalPrice));
      }, 600);
      setForm({
        items: update.items.map((i) => ({ ...i, expiry: moment(i.expiry) })),
      });
      setStep(3);
    } else setStep(1);
  }, [update]);
  const onFinish = async (values) => {
    setLoader(true);
    var items = values.items || [];
    items = items.map((i, ind) => ({
      ...i,
      totalPrice: totalPrice[ind],
      priceWithDiscount: price[ind],
      discount: i.discount ? i.discount : 0,
    }));
    let res;
    res = await postMethod("sale/detail", {
      items,
      saleType,
      customerId,
      grandTotal: values.grandTotal,
      invoiceNo,
      totalPrice: price.reduce((sum, i) => i + sum, 0),
    });
    if (res?.success) {
      setSummary({ ...res.data, items });
      handlePrint();
      reset();
    }
    setLoader(false);
  };
  const reset = () => {
    setStep(1);
    setInvoiceNo(null);
    setTotalPrice([]);
    setOkPrice([]);
    setCustomerId("");
    setSelectedItems([]);
    setSearch("");
    onchange("grandTotal", "");
    setSaleType("cash");
    setForm({});
  };
  const onchange = (key, val) => {
    setState((s) => ({ ...s, [key]: val }));
  };
  const getItems = async (params = {}) => {
    try {
      setTableLoading(true);
      setStep(1);
      var res = await getMethod("items/detail", {
        ...params,
      });
      if (res?.success) {
        setItems(res.data);
      }
      setTableLoading(false);
    } catch (error) {
      ShowMessage(error?.message);
    }
  };
  const addItem = (val, item) => {
    if (!val)
      setSelectedItems((items) => {
        return items.filter((i) => i._id != item._id);
      });
    else
      setModalItem(item)
  };
  const onFinishAddingItem = (values) => {
    var temp = modalItem;
    if (values.discount) temp.discount = values.discount
    temp.quantity = values.quantity
    temp.salePrice = values.salePrice
    setSelectedItems((items) => {
      return [...items, temp];
    });
    setModalItem(null)
    setAddingItem({})
    if (form.items?.find(it => it._id == temp._id))
      setForm(fo => {
        var items = fo.items.filter(it => it._id != temp._id)
        fo.items = [...items]
        return fo
      });
    if (searchRef.current) {
      searchRef.current.focus();
      setSearch("");
    }

  }
  const searching = (e) => {
    setSearch(e.target.value);
    getItems({ q: e.target.value });
  };
  const onStep3 = () => {
    var items
    if (form && form?.items) {
      setForm((fo) => {
        var temp = [...fo.items];
        items = [
          ...selectedItems.map((i) => {
            var item = temp.find((it) => it._id == i._id);
            if (item) return item;
            else return i;
          }),
        ];
        fo.items = [...items];
        calculatePrice(fo.items)
        return fo;
      });
    } else if (!form?.items) {
      setForm((f) => ({ ...f, items: selectedItems }));
      calculatePrice()
    }
    setStep(3);
  };
  const calculatePrice = (__items) => {
    var items = __items || selectedItems
    if (Array.isArray(items)) {
      var okPrice = []
      var GPrice = []
      items.map(item => {
        var { discount, salePrice, quantity } = item
        if (!discount) discount = 0
        var price = salePrice * quantity;
        var dis = (discount * price) / 100;
        var totalPrice = price - dis;
        console.log({ totalPrice, price })
        GPrice.push(totalPrice)
        okPrice.push(price)
      })

      setTotalPrice([...GPrice])
      setOkPrice([...okPrice])
    }
  }

  const setPrice = (prevValues, currentValues, info, index) => {
    if (info.source == "internal") {
      var prevobj = prevValues.items[index];
      var currentObj = currentValues.items[index];
      var { quantity: pquanity, discount: pdiscount } = prevobj;
      var {
        salePrice: csalePrice,
        quantity: cquanity,
        discount: cdiscount,
      } = currentObj;
      if (!cdiscount && cquanity) cdiscount = 0;
      if (
        typeof cquanity == "number" &&
        typeof cdiscount == "number" &&
        (pquanity != cquanity || pdiscount != cdiscount)
      ) {
        var price = csalePrice * cquanity;
        var dis = (cdiscount * price) / 100;
        var totalPrice = price - dis;
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = price;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = totalPrice;
          return [...temp];
        });
        return true;
      } else if (!cquanity || (!cdiscount && pquanity && pdiscount)) {
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (totalPrice.length > 0) {
      onchange(
        "grandTotal",
        totalPrice.reduce((i, sum) => i + sum)
      );
    }
  }, [totalPrice]);

  const [saleType, setSaleType] = React.useState("cash");
  const modalProps = {
    dispItem: modalItem,
    feild: addingItem,
    item: modalItem,
    setPrice,
    totalPrice,
    feilds: [],
    update,
    index: 0,
    isAdding: true
  };
  return (
    <>
      <Modal
        title="Add Sale"
        visible={visible}
        centered
        maskClosable={false}
        width={"90vw"}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Space style={{ width: "100%" }} direction="vertical" size={"large"}>
          {step == 1 ? (
            <>
              <Row gutter={24}>
                <Col span={8}>
                  <label>Invoice #</label>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={invoiceNo}
                    disabled
                  />
                </Col>
                <Col span={8}>
                  <label>Select Customers</label>
                  <Select
                    showSearch
                    value={customerId}
                    onChange={(e) => setCustomerId(e)}
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    allowClear

                  >
                    {customers.map((i) => (
                      <Option value={i._id}>{i.name}</Option>
                    ))}
                  </Select>
                </Col>
                {customerId && (
                  <Col span={8}>
                    <label>Sale Type</label>
                    <Select
                      defaultValue="cash"
                      style={{ width: "100%" }}
                      value={saleType}
                      onChange={setSaleType}

                    >
                      <Option value="cash">Cash</Option>
                      <Option value="credit">Credit</Option>
                    </Select>
                  </Col>
                )}
              </Row>
              <Input ref={searchRef} placeholder="Search" value={search} onChange={searching} />
              <Table
                loading={tableLoading}
                pagination={false}
                rowKey={(i) => i._id}
                columns={tableCheckBoxItemsSale({ selectedItems, addItem })}
                dataSource={items}
              />
              <Row justify="space-between" gutter={24} className="top-2">
                <Button type="primary" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button type="primary" onClick={onStep3}>
                  Next
                </Button>
              </Row>
            </>
          ) : (
            <Form
              name="nest-messages"
                onFinish={values => onFinish({ ...values, grandTotal: state.grandTotal })}
              onValuesChange={(v, values) => setForm(values)}
              defaultValue={form}
              validateMessages={validateMessages}
            >
              <Form.List name="items" initialValue={form?.items}>
                {(feilds) => {
                  return feilds.map((feild, index) => {
                    var item = (form?.items && form.items[index]) || {};
                    var dispItem = item?.item || item;
                    const props = {
                      dispItem,
                      feild,
                      item,
                      setPrice,
                      totalPrice,
                      feilds,
                      update,
                      index,
                    };
                    return <SaleFormRow {...props} />;
                  });
                }}
              </Form.List>
              <Row gutter={24}>
                <Col span={3} offset={21}>
                  <b>Grand Total:</b>
                  <InputNumber
                    value={state.grandTotal}
                      onChange={(e) => onchange("grandTotal", e)}
                  />
                </Col>
              </Row>
              <Form.Item>
                <Row justify="space-between" gutter={24} className="top-2">
                  {!update ? (
                    <Button
                      loading={loader}
                      type="primary"
                        onClick={() => {
                          setStep(1);
                          setTimeout(() => {
                            searchRef.current.focus();
                            setSearch("");

                          }, 600);
                        }}
                    >
                      Back
                    </Button>
                  ) : (
                    <div />
                  )}
                  <div>
                    <Button loading={loader} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </Row>
              </Form.Item>
            </Form>
          )}
          {/* {summary && (
            <PurchaseSummary
              items={summary?.items?.map((i, ind) => ({
                ...i,
              }))}
              visible={true}
              title={"Sale Summary"}
              print={summary}
              data={{ grandTotal: summary.grandTotal }}
              tableData={saleSummaryReceiptTable()}
              onClose={() => setSummary(null)}
            />
          )} */}
        </Space>
        {modalItem && modalProps && <Modal okButtonProps={{ hidden: true }} width={"80%"} onCancel={() => {
          searchRef.current.focus()
          setSearch("");
          setModalItem(null);
        }} visible={true} >
          <Form
            name="sigleItemEntry"
            onFinish={onFinishAddingItem}
            onValuesChange={(v, values) => setAddingItem(values)}
            initialValues={modalItem}
            // form={formRef}
            validateMessages={validateMessages}
          >

            {modalProps.item && <SaleFormRow {...modalProps} />}
            <Button type="primary" id="submit" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Modal>}
        {summary && <>
          <div style={{ display: "none" }}>
            <SalePrint data={summary} ref={componentRef} />
          </div>
        </>}
      </Modal>
    </>
  );
};
