import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMethod, postMethod } from "../../api/functions";
import { ShowMessage } from "../../components/toast";
import { setDataAction } from "../../store/actions";
const { Option } = Select;
const TYPES = [
  "Cash Payment",
  "Cash Receipt",
  "Bank Payment",
  "Bank Receipt",
  "Journal Voucher",
  "Purchase Journal Voucher",
];
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
function Vouchers(params) {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const data = useSelector((state) => state.meta.accounts);
  const onFinish = async (values) => {
    setLoader(true);
    const res = await postMethod("voucher/detail", {
      ...values,
      type,
    });
    if (res?.success) {
      ShowMessage(`Voucher # ${res.data} has been created`, "success");
      form.resetFields();
      setType("");
    }
    setLoader(false);
    var res1 = await getMethod("users/accounts");
    if (res1?.success) dispatch(setDataAction({ accounts: res1?.data }));
  };
  return (
    <div>
      <label>Select Type</label>
      <Select style={{ width: "100%" }} value={type} onChange={setType}>
        {TYPES.map((i) => (
          <Option value={i}>{i}</Option>
        ))}
      </Select>
      {type && (
        <>
          <Form
            name="nest-messages1"
            onFinish={onFinish}
            form={form}
            style={{ marginTop: 20 }}
            validateMessages={validateMessages}
          >
            <Form.Item
              name={"createdAt"}
              label="Date"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker defaultValue={moment()} format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item
              name={"note"}
              label="Notes"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"amount"}
              label="Amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name={"drAccount"}
              label="Dr Account"
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Please select"
                allowClear
                filterOption={(value, node) => node.children.toLowerCase().match(value)}
              >
                {data.map((i) => (
                  <Option value={i._id}>
                    {i.title +
                      " - " +
                      i.category?.type +
                      " - " +
                      Math.abs(i.balance) +
                      (i.balance >= 0 ? "CR" : "DR")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"crAccount"}
              label="Cr Account"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Please select"
                allowClear
                filterOption={(value, node) => node.children.toLowerCase().match(value)}
              >
                {data.map((i) => {
                  return (
                    <Option value={i._id}>
                      {i.title +
                        " - " +
                        i.category?.type +
                        " - " +
                        Math.abs(i.balance) +
                        (i.balance >= 0 ? "CR" : "DR")}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                style={{ marginTop: 20 }}
                loading={loader}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}

export default Vouchers;
