import { Col, DatePicker, Divider, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod } from "../../api/functions";
import { saleHistoryTable, saleStatTable, saleTable } from "../../components/tables/SaleTable";

const { RangePicker } = DatePicker;
const { Option } = Select;
function SaleReport(params) {
  const [type, setType] = useState("daily");
  const users = useSelector((state) => state.meta.users);
  const [selectedUser, setSelectedUser] = useState();
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [state, type, selectedUser]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("sale/profit", { ...state, type, user: selectedUser });
    if (res?.success) {
      setData(res.data.map((record) => {
        var netSale = record.grossSale - record.totalSaleReturn;
        var avgCostOfSale = record.costOfSale - record.costOfSaleReturn
        var grandTotalAmount = (netSale - avgCostOfSale)?.toFixed(2)

        const amount = netSale - avgCostOfSale
        var value = (amount / netSale * 100).toFixed(2)
        var gpPercentage = Number(value)
        return {
          ...record, grandTotalAmount, gpPercentage, netSale: (record.grossSale - record.totalSaleReturn), avgCostOfSale
        }
      }));
    }
    setLoader(false);
  };
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Selet Type</label>
          <Select
            showSearch
            value={type}
            onChange={(e) => setType(e)}
            style={{ width: "100%" }}
            placeholder="Please select"
            allowClear
          >
            <Option value={"daily"}>Daily</Option>
            <Option value={"monthly"}>Monthly</Option>
          </Select>
        </Col>
        <Col span={12}>
          <label>Selet Range</label>
          <br />
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={setDate}
            style={{ width: "100%" }}
            picker={type == "daily" ? "daily" : "month"}
          />
        </Col>
      </Row>
      <Col span={12}>
        <label>Selet User</label>
        <Select
          showSearch
          value={selectedUser}
          onChange={(e) => setSelectedUser(e)}
          style={{ width: "100%" }}
          placeholder="Please select"
          allowClear
        >
          {users?.map(item => <Option value={item._id}>{item.name}</Option>)}
        </Select>
      </Col>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={saleHistoryTable({})}
        dataSource={[...(data || []), {
          grandTotalAmount: data.reduce((sum, item) => sum + Number(item.grandTotalAmount), 0),
          totalSale: data.reduce((sum, item) => sum + Number(item.totalSale), 0),
          discount: data.reduce((sum, item) => sum + Number(item.discount), 0),
          grossSale: data.reduce((sum, item) => sum + Number(item.grossSale), 0),
          totalSaleReturn: data.reduce((sum, item) => sum + Number(item.totalSaleReturn), 0),
          netSale: data.reduce((sum, item) => sum + Number(item.netSale), 0),
          avgCostOfSale: data.reduce((sum, item) => sum + Number(item.avgCostOfSale), 0),
          gpPercentage: (data.reduce((sum, item) => sum + Number(item.gpPercentage), 0) / data.length).toFixed(2),
          _id: "TOTAL:"
        }]}
      />
    </div>
  );
}

export default SaleReport;
