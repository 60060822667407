import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
  TreeSelect,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMethod, postMethod, putMethod } from "../../api/functions";
import PurchaseAddRow from "../Form/PurchaseAddRow";
import { tableCheckBoxItems } from "../tables/ItemTable";
import { ShowMessage } from "../toast";
import { ItemPurchaseHistory } from "./itemPurchaseHistory";
import { PurchaseSummary } from "./PurchaseSummary";

/* eslint-disable no-template-curly-in-string */
const { TreeNode } = TreeSelect;
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export const PurchaseForm = ({ visible, onClose, update }) => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({});
  const [summary, setSummary] = useState([]);
  const [items, setItems] = useState([]);
  const [formRef] = Form.useForm()
  const [price, setOkPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [history, setHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [modalItem, setModalItem] = useState(null)
  const [addingItem, setAddingItem] = useState({})
  const searchRef = useRef()
  const [state, setState] = useState({
    supplier: "",
    invoiceNo: "",
    grandTotal: "",
    tax: 0,
  });
  useEffect(() => {
    if (update) {
      setTimeout(() => {
        setTotalPrice(update.items.map((i) => i.totalPrice));
      }, 600);
      var items = update.items.map((i) => ({
        ...i.item,
        salePrice: i.salePrice,
        purchasePrice: i.purchasePrice,
        batch: i.batch,
        quantity: i.quantity,
        totalPrice: i.totalPrice,
        extrawQuantity: i.extrawQuantity,
        discountInPercent: i.discountInPercent,
        expiry: moment(i.expiry),
        totalSalePrice: i.salePrice
      }))
      setForm({
        items: items,
      });
      setSelectedItems([...items])
      setState(s => ({ ...s, invoiceNo: update.invoiceNo, supplier: update.supplier._id }))
      setStep(3);
    } else setStep(1);
  }, [update]);
  const suppliers = useSelector((state) => state.meta.suppliers);
  const onFinish = async (values, valid) => {
    // return console.log({ values })
    // console.log({ valid })
    setLoader(true);
    var items = values.items || [];
    items = items.map((i, ind) => ({
      ...i,
      totalPrice: totalPrice[ind],
      priceWithDiscount: price[ind],
      discount: i.discount ? i.discount : 0,
      extrawQuantity: i.extrawQuantity ? i.extrawQuantity : 0,
    }));
    let res;
    // if (update)
    //   res = await putMethod(valid ? "purchase/draft" : "purchase/detail", {
    //     items,
    //     _id: update._id,
    //     grandTotal: state.grandTotal,
    //     totalPrice: price.reduce((sum, i) => i + sum, 0),
    //     purchaseDraftId: update ? update._id : null
    //   });
    // else
    res = await postMethod(valid ? "purchase/draft" : "purchase/detail", {
      items,
      ...state,
      taxAmount: state.tax * (state.grandTotal / 100),
      taxPercentage: state.tax,
      totalPrice: price.reduce((sum, i) => i + sum, 0),
      purchaseDraftId: update ? update._id : null
    });
    if (res?.success) onClose();
    setLoader(false);
    // console.log(values);
  };
  const onchange = (key, val) => {
    console.log({ [key]: val })
    setState((s) => ({ ...s, [key]: val }));
  };
  console.log({ state })
  const getItems = async (params = {}) => {
    try {
      if (!state.invoiceNo) throw new Error("Please Enter Invoice #");
      if (!state.supplier) throw new Error("Please Enter supplier");
      setTableLoading(true);
      setStep(2);
      var res = await getMethod("items/detail", {
        // supplier: state.supplier,
        ...params,
      });
      if (res?.success) {
        setItems(res.data);
      }
      setTableLoading(false);
    } catch (error) {
      ShowMessage(error?.message);
    }
  };
  const addItem = (val, item) => {
    if (!val)
      return setSelectedItems((items) => {
        return items.filter((i) => i._id != item._id);
    });
    setModalItem(item)

    // setSelectedItems((items) => {
    //   if (val) return [...items, item];
    //   else return items.filter((i) => i._id != item._id);
    // });
  };
  const onFinishAddingItem = () => {
    var temp = modalItem;
    if (addingItem.discount) temp.discount = addingItem.discount
    if (addingItem.extrawQuantity) temp.extrawQuantity = addingItem.extrawQuantity
    temp.batch = addingItem.batch
    temp.expiry = addingItem.expiry
    temp.purchasePrice = addingItem.purchasePrice
    temp.quantity = addingItem.quantity
    temp.totalSalePrice = addingItem.totalSalePrice
    setSelectedItems((items) => {
      return [...items, temp];
    });
    setModalItem(null)
    setAddingItem({})
    if (form.items?.find(it => it._id == temp._id))
      setForm(fo => {
        var items = fo.items.filter(it => it._id != temp._id)
        fo.items = [...items]
        return fo
      });
    if (searchRef.current)
      searchRef.current.focus()
  };
  const searching = (e) => {
    setSearch(e.target.value);
    getItems({ q: e.target.value });
  };
  const onStep3 = () => {
    var items;
    if (form && form?.items) {

      setForm((fo) => {
        var temp = [...fo.items];
        items = [
          ...selectedItems.map((i) => {
            var item = temp.find((it) => it._id == i._id);
            if (item) return item;
            else return i;
          }),
        ];
        fo.items = [...items];
        return fo;
      });
      // setTotalPrice([]);
    } else if (!form?.items) setForm((f) => ({ ...f, items: selectedItems }));
    setStep(3);
    if (items) {
      formRef.setFieldsValue({ items })
      setTotalPrice([])
      setPrice([])
    }
  };
  const setPrice = (prevValues, currentValues, info, index) => {
    if (info.source == "internal") {
      var prevobj = prevValues.items[index];
      var currentObj = currentValues.items[index];
      var { } = prevobj;
      var {
        purchasePrice: cpurchasePrice,
        quantity: cquanity,
        discount: cdiscount,
      } = currentObj;
      if (cquanity && cpurchasePrice) {
        var price = cpurchasePrice * cquanity;
        var dis;
        if (!cdiscount) dis = 0;
        else dis = (cdiscount * price) / 100;
        var totalPrice = price - dis;
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = price;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = totalPrice;
          return [...temp];
        });
        return true;
      } else if (!cquanity || !cpurchasePrice) {
        setOkPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        setTotalPrice((p) => {
          var temp = [...p];
          temp[index] = 0;
          return [...temp];
        });
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (totalPrice.length > 0)
      onchange(
        "grandTotal",
        totalPrice.reduce((i, sum) => i + sum)
      );
  }, [totalPrice]);
  // useEffect(() => {
  //   if (!update)
  //   setSelectedItems([]);
  // }, [state.supplier]);
  const getHistory = async (itemId) => {
    var res = await getMethod("items/history", { item: itemId });
    if (res?.success) setHistory(res.data);
  };
  const modalProps = {
    dispItem: modalItem,
    feild: modalItem,
    item: modalItem,
    update,
    totalPrice,
    feilds: [],
    index: 0,
    getHistory,
    setPrice,
    isAdding: true
  };
  return (
    <>
      <Modal
        title="Add Purchase"
        visible={visible}
        centered
        maskClosable={false}
        width={"90vw"}
        okButtonProps={{ hidden: true }}
        onCancel={onClose}
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          {step == 1 ? (
            <Row gutter={24}>
              <Col span={12}>
                <label>Supplier</label>
                <TreeSelect
                  showSearch
                  value={state.supplier}
                  onChange={(e) => onchange("supplier", e)}
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  filterTreeNode={(value, node) => node.title.toLowerCase().match(value)}
                >
                  {suppliers.map((i) => (
                    <TreeNode value={i._id} title={i.name} />
                  ))}
                </TreeSelect>
              </Col>
              <Col span={12}>
                <label>Invoice #</label>
                <Input
                  value={state.invoiceNo}
                  onChange={(e) => onchange("invoiceNo", e.target.value)}
                />
              </Col>
              <Row className="top-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      getItems({});
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Row>
          ) : step == 2 ? (
            <>
                <Input ref={searchRef} autoFocus placeholder="Search" value={search} onChange={searching} />
              <Table
                loading={tableLoading}
                pagination={false}
                rowKey={(i) => i._id}
                columns={tableCheckBoxItems({ selectedItems, addItem })}
                dataSource={items}
              />
              <Row justify="space-between" gutter={24} className="top-2">
                <Button type="primary" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button type="primary" onClick={onStep3}>
                  Next
                </Button>
              </Row>
            </>
            ) : step == 3 ? (
            <Form
              name="nest-messages"
                  onFinish={values => onFinish(values, true)}
              onValuesChange={(v, values) => setForm(values)}
              defaultValue={form}
                  form={formRef}
              validateMessages={validateMessages}
            >
              <Space
                direction="vertical"
                size={"large"}
                style={{ width: "100%" }}
              >
                <Form.List name="items" initialValue={form?.items}>
                  {(feilds) => {
                    return feilds.map((feild, index) => {
                      var item = (form?.items && form.items[index]) || {};
                      var dispItem = item?.item || item;
                      var props = {
                        dispItem,
                        feild,
                        item,
                        update,
                        totalPrice,
                        feilds,
                        index,
                        getHistory,
                        setPrice,
                      };
                      return <PurchaseAddRow {...props} />;
                    });
                  }}
                </Form.List>
                <Row gutter={24}>
                  <Col span={3} offset={21}>
                        <b>Tax in Percentage:</b>
                        <InputNumber
                          value={state.tax}
                          onChange={(e) => onchange("tax", e)}
                        />
                      </Col>
                      <Col span={3} offset={21}>
                    <b>Grand Total:</b>
                    <InputNumber
                      value={state.grandTotal}
                          onChange={(e) => onchange("grandTotal", e)}
                    />
                  </Col>
                </Row>
                    <Row gutter={24}>
                      <Col span={3} offset={21}>
                        <b>Amount After Tax: </b>
                        <b>{state.grandTotal + (state.tax * state.grandTotal / 100)}</b>
                      </Col>
                    </Row>
                <Form.Item>
                      <Row justify="space-between" gutter={24} className="top-2">
                        <Button
                          loading={loader}
                          type="primary"
                          onClick={() => { setStep(2); getItems({}); }}
                        >
                          Back
                        </Button>
                    <div>
                      <Button
                        onClick={() => setSummary(form.items)}
                        type="primary"
                        style={{ marginRight: 20 }}
                      >
                        See Summary
                      </Button>
                          <Button loading={loader} type="primary" style={{ marginRight: 20 }} onClick={async () => {
                            var errors = formRef.validateFields()
                            var e = await errors
                            if (e.items)
                              onFinish(e)
                          }}>
                            Submit
                          </Button>
                          <Button loading={loader} type="primary" id="submit" htmlType="submit">
                            Save
                      </Button>
                    </div>
                  </Row>
                </Form.Item>
              </Space>
            </Form>
          ) : null}
          {summary.length > 0 && (
            <PurchaseSummary
              items={summary?.map((i, ind) => ({
                ...i,
                totalPrice: totalPrice[ind],
              }))}
              visible={true}
              data={state}
              onClose={() => setSummary([])}
            />
          )}
          {history.length > 0 && (
            <ItemPurchaseHistory
              items={history}
              visible={history.length > 0 ? true : false}
              onClose={() => setHistory([])}
            />
          )}
        </Space>
        {modalItem && modalProps && <Modal okButtonProps={{ hidden: true }} width={"80%"} onCancel={() => {
          setModalItem(null);
        }} visible={true} >
          <Form
            name="sigleItemEntry"
            // onFinish={(values) => console.log("values")}
            onValuesChange={(v, values) => setAddingItem(values)}
            initialValues={modalItem}
            // form={formRef}
            validateMessages={validateMessages}
          >

            {modalProps.item && <PurchaseAddRow {...modalProps} />}
            <Button onClick={onFinishAddingItem} type="primary" id="submit" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Modal>}
      </Modal>
    </>
  );
};
