import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Modal } from "antd";
import { SupplierForm } from "../../components/Modal/SupplierForm";
import { supplierTable } from "../../components/tables/SupplierTable";
import { getMethod } from "../../api/functions";
import { useDispatch, useSelector } from "react-redux";
import { setDataAction } from "../../store/actions";

function Supplier(params) {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [record, setRecord] = useState(null);
  const data = useSelector((state) => state.meta.suppliers);
  const dispatch = useDispatch();
  const getData = async () => {
    setLoader(true);
    var res = await getMethod("supplier/detail");
    if (res?.success) dispatch(setDataAction({ suppliers: res?.data }));
    setLoader(false);
    var _res = await getMethod("users/accounts");
    if (_res?.success) dispatch(setDataAction({ accounts: _res?.data }));
  };
  const onUpdate = (item) => {
    setRecord(item);
    setShow(true);
  };
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={supplierTable({ onUpdate })}
        dataSource={data}
      />
      {show && (
        <SupplierForm
          visible={show}
          data={record}
          onClose={() => {
            getData();
            setShow(false);
            setRecord(null);
          }}
        />
      )}
    </div>
  );
}

export default Supplier;
