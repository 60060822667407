import { Button, Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";
import { PurchaseSummary } from "../../components/Modal/PurchaseSummary";
import { SaleReturnForm } from "../../components/Modal/SaleReturnForm";
import { saleReturnTable, saleSummaryTable, saleTable } from "../../components/tables/SaleTable";

function SaleReturn(params) {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(null);
  const [item, setItem] = useState(null);
  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;
      if (key == "ArrowRight") {
        const p = currentPage;
        getData({ page: total / 10 > p ? p + 1 : p });
      }
      if (key == "ArrowLeft") {
        const p = currentPage;
        getData({ page: p > 1 ? p - 1 : p });
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [total, currentPage]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("sale/return", { ...params, date: new Date() });
    if (res?.success) {
      const { currentPage, total, data } = res;
      setCurrentPage(currentPage);
      setTotal(total);
      setData(data);
    }
    setLoader(false);
  };
  const onView = (record) => {
    setItem(record);
  };
  const onUpdate = (record) => {
    setUpdate(record);
    setShow(true);
  };
  const onAccountPress = (record) => {};
  return (
    <div>
      <Button onClick={() => setShow(true)} type="primary">
        Add New
      </Button>
      <Divider />
      <Table
        loading={loader}
        pagination={{
          position: ["bottomLeft"],
          total: total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          onChange: setCurrentPage,
        }}
        columns={saleReturnTable({ onView, onAccountPress, onUpdate })}
        dataSource={data}
      />
      {show && (
        <SaleReturnForm
          visible={show}
          update={update}
          onClose={() => {
            setShow(false);
            getData({ page: 1 });
            setItem(null);
            setUpdate(null);
          }}
        />
      )}
      {item?.items?.length > 0 && (
        <PurchaseSummary
          title="Sale Summary"
          visible={true}
          supplier={item.supplier}
          data={{ ...item, items: [] }}
          items={item.items}
          onClose={() => setItem(null)}
          tableData={saleSummaryTable()}
        />
      )}
    </div>
  );
}

export default SaleReturn;
