import { GET_DATA } from "./types";

const INIT_STATE = {
  suppliers: [],
  menufecturers: [],
  itemCategories: [],
  customers: [],
  accCategories: [],
  accounts: [],
  users: []
};

const Posts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default Posts;
