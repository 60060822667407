export const manufacturerTable = ({ onUpdate }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text) => text ?? "-",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (text) => text ?? "-",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
    render: (text) => text ?? "-",
  },

  // {
  //   title: "Action",
  //   key: "action",
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <a onClick={() => onUpdate(record)}>Update</a>
  //     </Space>
  //   ),
  // },
];
