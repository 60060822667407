import { Col, DatePicker, Divider, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getMethod } from "../../api/functions";
import { saleStatTable, saleTable } from "../../components/tables/SaleTable";

const { RangePicker } = DatePicker;
const { Option } = Select;
function SaleHistory(params) {
  const [type, setType] = useState("daily");
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [state, type]);
  const getData = async (params = {}) => {
    setLoader(true);
    var res = await getMethod("sale/stats", { ...state, type });
    if (res?.success) {
      setData(res.data);
    }
    setLoader(false);
  };
  const setDate = (e) => {
    const [startDate, endDate] = e;
    setState({
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    });
  };
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <label>Selet Type</label>
          <Select
            showSearch
            value={type}
            onChange={(e) => setType(e)}
            style={{ width: "100%" }}
            placeholder="Please select"
            allowClear
          >
            <Option value={"daily"}>Daily</Option>
            <Option value={"monthly"}>Monthly</Option>
          </Select>
        </Col>
        <Col span={12}>
          <label>Selet Range</label>
          <br />
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={setDate}
            style={{ width: "100%" }}
            picker={type == "daily" ? null : "month"}
          />
        </Col>
      </Row>
      <Divider />
      <Table
        loading={loader}
        pagination={false}
        columns={saleStatTable({})}
        dataSource={data}
      />
    </div>
  );
}

export default SaleHistory;
