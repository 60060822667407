import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Main from "./layout/Main/index";
import Login from "./views/Login";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { PersistGate } from "redux-persist/integration/react";
import reduxStore from "./store";
import { Provider, useDispatch } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "antd/dist/antd.css";
import { getMethod } from "./api/functions";
import { setDataAction } from "./store/actions";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    var user = localStorage.getItem("user");
    if (user) {
      var res = await getMethod("users/detail");
      if (res?.success) {
        localStorage.setItem("user", JSON.stringify(res?.data));
        localStorage.setItem("admin", JSON.stringify(res?.admin));
        setUser(res?.data);
        Promise.all([
          getMethod("menufecturer/detail"),
          getMethod("supplier/detail"),
          getMethod("item-category/detail"),
          getMethod("customers/detail"),
          getMethod("users/account-categories"),
          getMethod("users/accounts"),
          getMethod("users/all", { additionalType: "admin" })

        ]).then(([men, sup, cat, cus, acc, _acc, users]) => {
          if (men?.success)
            dispatch(setDataAction({ menufecturers: men?.data }));
          if (sup?.success) dispatch(setDataAction({ suppliers: sup?.data }));
          if (cat?.success)
            dispatch(setDataAction({ itemCategories: cat?.data }));
          if (cus?.success) dispatch(setDataAction({ customers: cus?.data }));
          if (acc?.success)
            dispatch(setDataAction({ accCategories: acc?.data }));
          if (_acc?.success) dispatch(setDataAction({ accounts: _acc?.data }));
          if (users?.success)
            dispatch(setDataAction({ users: users?.data }));
        });
      }
    }
    setLoading(false);
  };
  if (loading) return <div className="loader" />;
  else
    return (
      <Suspense fallback={<div className="loader" />}>
        <Router>
          <Switch>
            {user && (
              <Route path={"/main"} render={(props) => <Main {...props} />} />
            )}
            <Route path={"/auth/login"} component={Login} />
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </Router>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Suspense>
    );
}
function ReduxProvider() {
  const store = reduxStore();
  return (
    <Provider store={store.store}>
      <PersistGate
        loading={<div className="loader" />}
        persistor={store.persistor}
      >
        <App />
      </PersistGate>
    </Provider>
  );
}
export default ReduxProvider;
