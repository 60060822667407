import moment from "moment";

export const accountCategoriesTable = ({ onUpdate }) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Sub Types",
    dataIndex: "subTypes",
    key: "subTypes",
  },
];
export const accountsTable = ({ onUpdate }) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (prop) => prop?.name || "-",
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    render: (prop) => prop?.name || "-",
  },
  {
    title: "Category Title",
    dataIndex: "category",
    key: "category",
    render: (prop) => prop?.title || "-",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (prop) => prop?.type + " / " + prop?.subTypes || "-",
  },
];
export const ledgerTable = () => [

  {
    title: "Date",
    dataIndex: "createdAt",
    key: "_id",
    render: (date) => date && moment(date).format("DD/MM/YYYY") || "-",
  },
  {
    title: "Voucher No",
    dataIndex: "voucherNo",
    key: "_id",
    render: (item) => item || "-"
  },
  {
    title: "Voucher Type",
    dataIndex: "type",
    key: "_id",
    render: (item, record) => item && (item + "(" + record.invoiceNo + ")") || "-"
  },
  {
    title: "Description",
    dataIndex: "note",
    key: "_id",
    render: (item) => item || "-"
  },
  {
    title: "Account Head",
    dataIndex: "accountTo",
    key: "accountTo",
    render: (accountTo) => accountTo?.title || "-",
  },
  {
    title: "Dr",
    dataIndex: "drAmount",
    key: "drAmount",
    render: (drAmount) => {
      return <p>{drAmount || "-"}</p>;
    },
  },
  {
    title: "Cr",
    dataIndex: "crAmount",
    key: "crAmount",
    render: (crAmount) => {
      return <p>{crAmount || "-"}</p>;
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (crAmount) => {
      return <p>{crAmount || "-"}</p>;
    },
  },

];
export const trailBalanceTable = () => [

  {
    title: "Account Head",
    dataIndex: "account",
    key: "_id",
    render: (item) => item || "-"
  },
  {
    title: "Dr",
    dataIndex: "dr",
    key: "_id",
    render: (item) => item || "-"
  },
  {
    title: "Cr",
    dataIndex: "cr",
    key: "_id",
    render: (item) => item || "-"
  },

];
export const journalTable = () => [

  {
    title: "Date",
    dataIndex: "createdAt",
    key: "_id",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Voucher No",
    dataIndex: "voucherNo",
    key: "_id",
  },
  {
    title: "Voucher Type",
    dataIndex: "type",
    key: "type",
    render: (item, record) => record.invoiceNo ? item + " (" + record?.invoiceNo + ")" : item
  },
  {
    title: "Description",
    dataIndex: "note",
    key: "note",
  },
  {
    title: "Account Heads",
    dataIndex: "_id",
    key: "_id",
    render: (id, record) => {
      return (
        <p>
          {record.crAccount.title}
          <br />
          {record.drAccount.title}
        </p>
      );
    },
  },
  {
    title: "Dr",
    dataIndex: "_id",
    key: "_id",
    render: (id, record) => {
      return (
        <p>
          -<br />
          {record.drAmount}
        </p>
      );
    },
  },
  {
    title: "Cr",
    dataIndex: "_id",
    key: "_id",
    render: (id, record) => {
      return (
        <p>
          {record.crAmount}
          <br />-
        </p>
      );
    },
  },

];

export const cashActivityTable = () => [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: i => i || "-"
  },
  {
    title: "Doc No.",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
    render: i => i || "-"
  },
  {
    title: "Cash A/C Head",
    dataIndex: "cashAccHead",
    key: "cashAccHead",
    render: i => i || "-"
  },
  {
    title: "Cash Paid/Received",
    dataIndex: "otherAccountHead",
    key: "otherAccountHead",
    render: i => i || "-"
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    render: i => i || "-"
  },
  {
    title: "Received",
    dataIndex: "received",
    key: "received",
    render: i => i?.toFixed(2) || "-"
  },
  {
    title: "Paid",
    dataIndex: "paid",
    key: "paid",
    render: i => i?.toFixed(2) || "-"
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: i => i?.toFixed(2) || "-"
  },
];